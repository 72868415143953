import { Auth } from 'aws-amplify';
import axios from 'axios';
import getValidUrl from './getValidUrl';

export const viewPrivateFileCurrentWindow = async (fullImageUrl) => {
  try {
    if (fullImageUrl.includes('eventhorizon-assets')) {
      const user = await Auth.currentSession();

      let filenamePath = fullImageUrl.split('.com/')[1];

      let dataShape = {
        operation: 'getObject',
        file_name: filenamePath,
        bucket_name: 'eventhorizon-assets',
      };

      const signedUrl = await axios({
        method: 'POST',
        url: '/upload/signed',
        headers: {
          idtoken: user.idToken.jwtToken,
        },
        data: dataShape,
      });

      return signedUrl.data;
    } else {
      return fullImageUrl;
    }
  } catch (error) {
    console.log(error);
    return fullImageUrl;
  }
};

export const viewPrivateFileNewWindow = async (fullFileUrl) => {
  try {
    if (fullFileUrl.includes('eventhorizon-assets')) {
      if (!fullFileUrl.includes('eventhorizon-assets-public')) {
        fullFileUrl = fullFileUrl.replaceAll('+', ' ');
        const user = await Auth.currentSession();

        let filenamePath = fullFileUrl.split('.com/')[1];

        let dataShape = {
          operation: 'getObject',
          file_name: filenamePath,
          bucket_name: 'eventhorizon-assets',
        };

        const signedUrl = await axios({
          method: 'POST',
          url: '/upload/signed',
          headers: {
            idtoken: user.idToken.jwtToken,
          },
          data: dataShape,
        });

        window.open(signedUrl.data, '_blank');
      } else {
        window.open(getValidUrl(fullFileUrl), '_blank');
      }
    } else {
      window.open(getValidUrl(fullFileUrl), '_blank');
    }
  } catch (error) {
    console.log(error);
    window.open(getValidUrl(fullFileUrl), '_blank');
  }
};
