import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import prepareObject from '../../Utilities/newObjectFromArrayFieldsAgenda';
import AddToCalendarHOC from 'react-add-to-calendar-hoc';
import { SHARE_SITES } from 'react-add-to-calendar-hoc';
import DialogWrapperAgenda from '../../Components/DialogWrapperAgenda';
import ButtonWrapper from '../../Components/ButtonWrapper';
import AddIcon from '@material-ui/icons/Add';
import trackAction from '../../Store/Actions/tracker';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import moment from 'moment';
import ChatProfile from '../../Components/ChatProfile';
import Tooltip from '@material-ui/core/Tooltip';

import getTwilioUserIdentity from '../../Utilities/getTwilioUserIdentity';
import isOnline from '../../Utilities/isOnline';
import { viewPrivateFileNewWindow } from '../../Utilities/viewPrivateFile';

// icons
import WorkIcon from '@material-ui/icons/Work';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import addBriefcaseElement from '../../Store/Actions/addBriefcaseElement';
import deleteBriefcaseElement from '../../Store/Actions/deleteBriefcaseElement';
import { queryApi } from '../../Services/queryApi';
import { eventId } from '../../config';

const useStyles = (theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
  },
  underTabsContainer: {
    // padding: theme.spacing(2, 1),
    textAlign: 'center',
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
    textDecoration: 'none',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  linePhoto: {
    borderRadius: '50%',
    width: '5rem',
    height: '5rem',
    [theme.breakpoints.down('xs')]: {
      width: '3rem',
      height: '3rem',
    },
  },
  lineTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textAlign: 'left',
    paddingBottom: theme.spacing(1),
  },
  lineAbstract: {
    fontSize: '1rem',
    textAlign: 'left',
  },
  lineSpeakerName: {
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  lineSpeakerInfo: {
    fontSize: '1rem',
    textAlign: 'left',
  },
  tabSingle: {
    minWidth: 'auto',
  },
  mobileButtons: {
    maxWidth: '50%',
  },
  calendarContainer: {
    // display: 'flex',
  },
  linkStyles: {
    textDecoration: 'none',
    display: 'block',
    textAlign: 'center',
    padding: theme.spacing(1),
    color: 'black',
    fontSize: '1.25em',
  },
  calendarButton: {
    width: '40%',
    height: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    // borderRadius: '0px 15px 0px 5px',
  },
  mobileTabButton: {
    width: '100%',
  },
  mobileTabButtonSelected: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
});

class WorkshopFull extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 1,
      agenda: null,
      firstPresenter: null,
    };
  }

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  recordEvent = (data, name) => {
    let newName = name;
    if (name === 'Workshop' && this.props.workshop.archive_link) {
      newName = 'Archive';
    }
    const event = {
      action: 'Clicked',
      resource_type: newName,
      resource_id: data.abstract_id,
      url_visited: '/workshop',
      resource_account_id: data.account_id,
      resource_account_name: data.account_name,
      resource_name: data.title,
    };

    this.props.trackAction(event);
  };

  trackCalendarClick(e) {
    const event = {
      action: 'Added',
      resource_type: 'PersonalCalendar',
      resource_id: this.props.workshop.abstract_id,
      url_visited: '/workshop',
      resource_account_id: this.props.workshop.account_id,
      resource_account_name: this.props.workshop.account_name,
      resource_name: this.props.workshop.title,
    };

    this.props.trackAction(event);
  }

  clickPresentationPdf() {
    const event = {
      action: 'Clicked',
      resource_type: 'Workshop Pdf / Workshop',
      resource_id: this.props.workshop.abstract_id,
      url_visited: '/workshop',
      resource_account_id: this.props.workshop.account_id,
      resource_account_name: this.props.workshop.name,
      resource_name: this.props.workshop.title,
    };

    this.props.trackAction(event);

    viewPrivateFileNewWindow(this.state.agenda.presentation_pdf);
  }

  clickIntroVideo() {
    const event = {
      action: 'Clicked',
      resource_type: 'Intro Video / Workshop',
      resource_id: this.props.workshop.abstract_id,
      url_visited: '/workshop',
      resource_account_id: this.props.workshop.account_id,
      resource_account_name: this.props.workshop.name,
      resource_name: this.props.workshop.title,
    };

    this.props.trackAction(event);

    viewPrivateFileNewWindow(this.state.agenda.presentation_video);
  }

  clickViewPresentation() {
    const event = {
      action: 'Clicked',
      resource_type: 'Workshop',
      resource_id: this.props.workshop.abstract_id,
      url_visited: '/workshop',
      resource_account_id: this.props.workshop.account_id,
      resource_account_name: this.props.workshop.name,
      resource_name: this.props.workshop.title,
    };

    this.props.trackAction(event);

    viewPrivateFileNewWindow(this.state.agenda.presentation_link);
  }

  clickViewArchive() {
    const event = {
      action: 'Clicked',
      resource_type: 'Archive',
      resource_id: this.props.workshop.abstract_id,
      url_visited: '/workshop',
      resource_account_id: this.props.workshop.account_id,
      resource_account_name: this.props.workshop.name,
      resource_name: this.props.workshop.title,
    };

    this.props.trackAction(event);

    viewPrivateFileNewWindow(this.state.agenda.archive_link);
  }

  componentDidMount() {
    if (this.props.workshop.contacts) {
      this.props.workshop.presenters = this.props.workshop.contacts
        .filter(
          (contact) =>
            contact.roles.includes('presenter') ||
            contact.roles.includes('Presenter')
        )
        .sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          }
          if (a.order < b.order) {
            return -1;
          }
          if (a.order === b.order) {
            return 0;
          }
          return null;
        });
      this.props.workshop.authors = this.props.workshop.contacts
        .filter(
          (contact) =>
            contact.roles.includes('author') || contact.roles.includes('Author')
        )
        .sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          }
          if (a.order < b.order) {
            return -1;
          }
          if (a.order === b.order) {
            return 0;
          }
          return null;
        });
      this.props.workshop.submitters = this.props.workshop.contacts
        .filter(
          (contact) =>
            contact.roles.includes('submitter') ||
            contact.roles.includes('Submitter')
        )
        .sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          }
          if (a.order < b.order) {
            return -1;
          }
          if (a.order === b.order) {
            return 0;
          }
          return null;
        });
    } else {
      if (!this.props.workshop) {
        this.props.workshop = {};
      }
      this.props.workshop.presenters = [];
      this.props.workshop.authors = [];
      this.props.workshop.submitters = [];
    }

    let requiredFields = [
      'description',
      'duration',
      'endDatetime',
      'location',
      'startDatetime',
      'title',
    ];
    // let requiredFields = ['description', 'duration', 'endDatetime', 'location', 'startDatetime', 'timezone', 'title']
    let calendarAbstract = prepareObject(
      this.props.workshop,
      requiredFields,
      'presentation'
    );

    this.setState({
      agenda: this.props.workshop,
      calendarAbstract,
    });

    this.recordEvent(this.props.workshop, 'Workshop');
  }

  briefcaseSelect() {
    this.setState({ tabValue: this.state.tabValue });
    if (this.props.online) {
      let favorited = false;
      if (this.props.briefcase.data) {
        for (let n = 0; n < this.props.briefcase.data.length; n++) {
          if (
            this.props.briefcase.data[n].resource_id ===
            this.state.agenda.abstract_id
          ) {
            favorited = true;
            break;
          }
        }
      }

      if (favorited) {
        this.props.deleteBriefcaseElement(this.state.agenda.abstract_id);
      } else {
        this.props.addBriefcaseElement(
          'Workshop',
          this.state.agenda.abstract_id,
          this.state.agenda.title
          // this.state.agenda.account_id,
          // this.props.user.user.account_id
        );
      }
    }
  }

  render() {
    const { classes } = this.props;
    const online = this.props.online;

    let isInThePast = false;
    let archiveButton;

    if (this.state.agenda) {
      let date = this.state.agenda.date.split('T')[0];
      let time = this.state.agenda.end_time;
      time = moment(this.state.agenda.end_time)
        .add(15, 'minutes')
        .format('HH:mm:ss');
      let timeNow = moment().format('HH:mm:ss');
      let dateNow = moment().format('YYYY-MM-DD');

      switch (true) {
        case date < dateNow:
          // date is in the past
          isInThePast = true;
          break;
        case date === dateNow:
          // date is today
          if (time < timeNow) {
            // time is in the past
            isInThePast = true;
          } else {
            // time is in the future
          }
          break;
        default:
          // time is in the future
          break;
      }

      if (this.state.agenda.archive_link) {
        archiveButton = (
          <Button
            color="inherit"
            variant="contained"
            className={classes.button}
            onClick={() => this.clickViewArchive()}
          >
            View Archived Presentation
          </Button>
        );
      } else {
        archiveButton = (
          <Button
            disabled
            color="inherit"
            variant="contained"
            className={classes.button}
          >
            Archive Not Available
          </Button>
        );
      }
    }

    let AddToCalendarButton = AddToCalendarHOC(
      ButtonWrapper,
      DialogWrapperAgenda
    );

    let agendaContent;

    if (this.state.agenda) {
      switch (this.state.tabValue) {
        case 1:
          agendaContent = (
            <React.Fragment>
              <Grid item xs={12}>
                <Hidden xsDown>
                  <Typography variant="h4" color="textPrimary">
                    {this.state.agenda.title}
                  </Typography>
                  <br />
                  <Typography variant="h6" color="textPrimary">
                    <b>Topic: </b>
                    {this.state.agenda.topic.split(';').join(', ')}
                  </Typography>
                </Hidden>
                <Hidden smUp>
                  <Typography variant="body1" color="textPrimary">
                    <b>{this.state.agenda.title}</b>
                  </Typography>
                  <br />
                  <Typography variant="body2" color="textPrimary">
                    <b>Topic: </b>
                    {this.state.agenda.topic.split(';').join(', ')}
                  </Typography>
                </Hidden>
                <br />
                <Divider className={classes.divider} variant="fullWidth" />
              </Grid>
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={12} sm={6}>
                  {isInThePast ? (
                    archiveButton
                  ) : (
                    <Button
                      color="inherit"
                      variant="contained"
                      className={classes.button}
                      onClick={() => this.clickViewPresentation()}
                    >
                      Launch Presentation
                    </Button>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {this.state.agenda.presentation_pdf ? (
                    <Button
                      color="inherit"
                      variant="contained"
                      className={classes.button}
                      onClick={() => this.clickPresentationPdf()}
                    >
                      Slides PDF
                    </Button>
                  ) : (
                    <Button
                      disabled
                      color="inherit"
                      variant="contained"
                      className={classes.button}
                    >
                      Slides PDF
                    </Button>
                  )}
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  {this.state.agenda.presentation_video ? (
                    <Button
                      color="inherit"
                      variant="contained"
                      className={classes.button}
                      onClick={() => this.clickIntroVideo()}
                    >
                      Intro Video
                    </Button>
                  ) : (
                    <Button
                      disabled
                      color="inherit"
                      variant="contained"
                      className={classes.button}
                    >
                      Intro Video
                    </Button>
                  )}
                </Grid> */}
              </Grid>
              <Grid item xs={12}>
                <div>
                  <AddToCalendarButton
                    className={classes.calendarContainer}
                    event={this.state.calendarAbstract}
                    items={[
                      SHARE_SITES.GOOGLE,
                      SHARE_SITES.ICAL,
                      SHARE_SITES.OUTLOOK,
                    ]}
                    dropdownProps={{
                      header: (
                        <Typography
                          className={classes.linkStyles}
                          variant="h6"
                          color="textPrimary"
                        >
                          Select an option
                        </Typography>
                      ),
                      addAction: (e) => {
                        this.trackCalendarClick(e);
                      },
                    }}
                    buttonProps={{
                      variant: 'contained',
                      size: 'large',
                      color: 'primary',
                      className: classes.calendarButton,
                      startIcon: <AddIcon />,
                    }}
                    linkProps={{ className: classes.linkStyles }}
                  ></AddToCalendarButton>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Divider className={classes.divider} variant="fullWidth" />
                <br />
                <Hidden xsDown>
                  <Typography variant="h6" color="textPrimary" align="center">
                    <b>Abstract </b>
                  </Typography>
                  <pre
                    style={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}
                  >
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      align="justify"
                    >
                      {this.state.agenda.content}
                    </Typography>
                  </pre>
                </Hidden>
                <Hidden smUp>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    align="center"
                  >
                    <b>Abstract </b>
                  </Typography>
                  <pre
                    style={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}
                  >
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      align="justify"
                    >
                      {this.state.agenda.content}
                    </Typography>
                  </pre>
                </Hidden>
              </Grid>
            </React.Fragment>
          );
          break;
        case 2:
          const keyValue = (key, value) => {
            if (value) {
              return (
                <>
                  <b>{key}:</b> {value}
                </>
              );
            }
          };
          agendaContent = (
            <React.Fragment>
              <Grid item xs={12}>
                {this.state.agenda.presenters.length ? (
                  <React.Fragment>
                    <Typography variant="h5" color="textPrimary">
                      <b>Presenters</b>
                    </Typography>
                    <Divider className={classes.divider} variant="fullWidth" />
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
                {this.state.agenda.presenters.map((presenter, index) => {
                  return (
                    <Grid
                      container
                      key={index}
                      style={{ cursor: 'pointer' }}
                      onClick={async () => {
                        const me = this.props.user.user;

                        const isMe =
                          getTwilioUserIdentity(me) === presenter?.email;

                        try {
                          if (!isMe) {
                            this.props
                              .twilioCreateJoinOneOnOneConversation(
                                presenter?.email
                              )
                              .catch((error) => {
                                console.log(
                                  '🚀 ~ file: AgendaRowItem.js ~ line 622 ~ onClick={ ~ error',
                                  error
                                );
                              });
                          }
                        } catch (error) {
                          console.log('chat error: ', error.message);
                        }
                      }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          className={classes.lineSpeakerName}
                          variant="h6"
                        >
                          {`${presenter.first_name} ${presenter.last_name}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <div
                          alt={'Presenter'}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                          }}
                        >
                          <ChatProfile
                            identity={presenter.email}
                            size={'4rem'}
                            photo_url={presenter.photo_url}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={9} style={{ paddingLeft: '8px' }}>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Title', presenter.title)}
                        </Typography>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Organization', presenter.account_name)}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.lineSpeakerInfo}
                        >
                          {keyValue('Department', presenter.department)}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.lineSpeakerInfo}
                        >
                          {keyValue('Expertise', presenter.expertise_area)}
                        </Typography>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Email', presenter.email)}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.lineSpeakerInfo}
                        >
                          {keyValue('Biography', presenter.bio)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider
                          className={classes.divider}
                          variant="fullWidth"
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item xs={12} style={{ paddingTop: '3vw' }}>
                {this.state.agenda.authors.length ? (
                  <React.Fragment>
                    <Typography variant="h5" color="textPrimary">
                      <b>Authors</b>
                    </Typography>
                    <Divider className={classes.divider} variant="fullWidth" />
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
                {this.state.agenda.authors.map((author, index) => {
                  return (
                    <Grid container key={index}>
                      <Grid item xs={12}>
                        <Typography
                          className={classes.lineSpeakerName}
                          variant="h6"
                        >
                          {`${author.first_name} ${author.last_name}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        {author.photo_url ? (
                          <img
                            className={classes.linePhoto}
                            src={author.photo_url}
                            alt={'Presenter'}
                          />
                        ) : null}
                      </Grid>
                      <Grid item xs={10}>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Title', author.title)}
                        </Typography>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Organization', author.account_name)}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.lineSpeakerInfo}
                        >
                          {keyValue('Department', author.department)}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.lineSpeakerInfo}
                        >
                          {keyValue('Expertise', author.expertise_area)}
                        </Typography>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Email', author.email)}
                        </Typography>
                        {/* <Typography variant='body1' className={classes.lineSpeakerInfo}>
                            {keyValue('Biography', author.bio)}
                          </Typography> */}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider
                          className={classes.divider}
                          variant="fullWidth"
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </React.Fragment>
          );
          break;
        default:
          agendaContent = <div>No Content</div>;
          break;
      }
    } else {
      agendaContent = <div></div>;
      return <div></div>;
    }

    let favorited = false;
    if (this.props.briefcase.data) {
      for (let n = 0; n < this.props.briefcase.data.length; n++) {
        if (
          this.props.briefcase.data[n].resource_id ===
          this.state.agenda.abstract_id
        ) {
          favorited = true;
          break;
        }
      }
    }

    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={12} sm={2} align="left">
          <Button onClick={() => this.props.closeWorkshop()}>
            <ChevronLeftIcon /> Back
          </Button>
        </Grid>
        <Hidden xsDown>
          <Grid item xs={12} sm={8}>
            <Tabs
              value={this.state.tabValue}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tooltip
                title={
                  online ? (
                    <Typography variant="subtitle2">
                      I want to save this workshop item to my briefcase to
                      access later
                    </Typography>
                  ) : (
                    <Typography variant="subtitle2">
                      User is Offline. Cannot perform action.
                    </Typography>
                  )
                }
              >
                <Tab
                  className={classes.tabSingle}
                  onClick={() => {
                    this.briefcaseSelect();
                  }}
                  icon={favorited ? <WorkIcon /> : <WorkOutlineIcon />}
                />
              </Tooltip>
              <Tab className={classes.tabSingle} label={'Presentation'} />
              <Tab className={classes.tabSingle} label={'Contributors'} />
            </Tabs>
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid container spacing={1} style={{ justifyContent: 'center' }}>
            <Grid item xs={6}>
              <Tooltip
                title={
                  !online && (
                    <Typography variant="subtitle2">
                      User is Offline. Cannot perform action.
                    </Typography>
                  )
                }
              >
                <Button
                  className={classes.mobileTabButton}
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    this.briefcaseSelect();
                  }}
                >
                  {favorited ? <WorkIcon /> : <WorkOutlineIcon />}
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Button
                color={this.state.tabValue === 1 ? 'primary' : 'default'}
                className={classes.mobileTabButton}
                variant="contained"
                disableElevation
                onClick={() => this.handleTabChange(null, 1)}
              >
                Presentation
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                color={this.state.tabValue === 2 ? 'primary' : 'default'}
                className={classes.mobileTabButton}
                variant="contained"
                disableElevation
                onClick={() => this.handleTabChange(null, 2)}
              >
                Contributors
              </Button>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden xsDown>
          <Grid item xs={false} sm={2} align="right" />
        </Hidden>
        <Grid item xs={12}>
          {/* <Paper className={classes.paper}> */}
          <Grid
            className={classes.underTabsContainer}
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            {agendaContent}
          </Grid>
          {/* </Paper> */}
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    briefcase: queryApi.endpoints.getBriefcase.select(eventId)(state),
    user: state.user,
    online: isOnline(state),
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getBriefcase: (eventId) =>
        queryApi.endpoints.getBriefcase.initiate(eventId),
      addBriefcaseElement: addBriefcaseElement,
      deleteBriefcaseElement: deleteBriefcaseElement,
      trackAction: trackAction,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(WorkshopFull));
