import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getConversations,
  getOpenConversations,
  getOpenProfile,
  setEngagement,
  getCreatingConversation,
} from '../Store/Reducers/twilio';
import SidebarConversation from '../Components/SidebarConversation';
import ChatConversation from '../Components/ChatConversation';
import getTwilioUserIdentity from '../Utilities/getTwilioUserIdentity';
import { useLocation } from 'react-router-dom';
import SidebarProfile from '../Components/SidebarProfile';
import SidebarNewMessage from '../Components/SidebarNewMessage';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SmsIcon from '@material-ui/icons/Sms';
import { colors } from '../config';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChatDirectory from './ChatDirectory';

export default function ChatSidebarMessages() {
  const dispatch = useDispatch();

  const [openTab, setOpenTab] = useState(0);
  const [isDirectMessages, setIsDirectMessages] = useState(true);
  const [isCreateNewMessage, setIsCreateNewMessage] = useState(false);

  const user = useSelector((state) => state.user.user);
  const contacts = useSelector((state) => state.twilio?.contacts);
  const unread = useSelector((state) => state.twilio?.unread);
  const engagement = useSelector((state) => state.twilio?.engagement);

  // console.log('🚀 ~ file: ChatSidebar.js ~ line 32 ~ ChatSidebar ~ user', user);
  const openConversations = useSelector(getOpenConversations);
  const page = 'sidebar';
  const openConversation = openConversations?.[page];

  const conversations = useSelector(getConversations);
  const creatingConversation = useSelector(getCreatingConversation);
  const openProfile = useSelector(getOpenProfile);

  const isAttendee = () => {
    let attendee = false;
    // console.log(
    //   '🚀 ~ file: EngagementZone.js ~ line 107 ~ isAttendee ~ user',
    //   user
    // );

    let roles = user?.roles
      ?.split(',')
      ?.map((role) => role.toLowerCase().trim());

    if (roles) {
      if (roles.includes('attendee')) {
        attendee = true;
      }
    }
    return attendee;
  };

  let contactsUnreadCount = 0;
  let followingUnreadCount = 0;

  Object.keys(conversations).forEach((key) => {
    if (conversations[key]?.uniqueName?.startsWith('GROUP_')) {
      if (conversations[key]?.myAttributes?.following) {
        followingUnreadCount += conversations[key].unread || 0;
      }
    } else {
      contactsUnreadCount += conversations[key].unread || 0;
    }
  });

  let directMessageUnreadCount = 0;
  let openRoomUnreadCount = 0;
  let openRooms = [];
  let directMessages = [];
  Object.keys(conversations).forEach((key) => {
    if (conversations[key]?.client?.uniqueName?.startsWith('GROUP_')) {
      openRoomUnreadCount += unread[conversations[key]?.client?.sid] || 0;
      openRooms.push({ ...conversations[key] });
    } else {
      directMessageUnreadCount += unread[conversations[key]?.client?.sid] || 0;
      directMessages.push(conversations[key]);
    }
  });
  openRooms.sort((a, b) => {
    if (!a.lastMessageCreated) return 1;
    if (!b.lastMessageCreated) return -1;
    return a.lastMessageCreated > b.lastMessageCreated ? -1 : 1;
  });

  // const openRooms = conversations?.filter((conversation) =>
  //   conversation?.client?.uniqueName?.startsWith('GROUP_')
  // );
  // console.log(
  //   '🚀 ~ file: ChatSidebarMessages.js ~ line 75 ~ ChatSidebarMessages ~ conversations',
  //   conversations
  // );

  directMessages = directMessages
    // Show Non Group Conversations with at least 1 message in it or that I own
    ?.filter((conversation) => {
      // hide conversations that I am the only particiant
      if (conversation?.client?.participants.size === 1) return false;
      // hide conversations with 2 particiants WITH no message
      // and I am not the owner
      if (
        conversation?.client?.participants.size === 2 &&
        !conversation.client?.lastMessage &&
        conversation.client.createdBy !== getTwilioUserIdentity(user)
      )
        return false;
      return true;
    });

  directMessages.sort((a, b) => {
    if (!a.lastMessageCreated) return 1;
    if (!b.lastMessageCreated) return -1;
    return a.lastMessageCreated > b.lastMessageCreated ? -1 : 1;
  });

  const location = useLocation();
  // console.log('location: ', location);
  if (creatingConversation) {
    return (
      <div
        style={{
          ...styles.chatRoot,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (location.pathname === '/engagementzone') {
    return null;
  }

  if (engagement === 'newMessage' && isCreateNewMessage) {
    return (
      <div style={styles.chatRoot}>
        <SidebarNewMessage
          setIsCreateNewMessage={setIsCreateNewMessage}
          page={'sidebar'}
        />
      </div>
    );
  }

  if (engagement === 'profile' && openProfile) {
    return (
      <div style={styles.chatRoot}>
        <SidebarProfile openProfile={openProfile} page={'sidebar'} />
      </div>
    );
  }
  if (openConversation?.sid) {
    return (
      <div
        style={{ ...styles.chatRoot, backgroundColor: colors.backgroundGray }}
      >
        <ChatConversation
          conversationSid={openConversation.sid}
          page={'sidebar'}
        />
      </div>
    );
  }

  return (
    <div style={styles.chatRoot}>
      <div
        style={{
          // overflowY: 'auto',
          // overflowX: 'hidden',
          width: '100%',
          height: '100%',

          flex: 1,
          paddingBottom: 10,
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              boxShadow: '0 0 15px -7px #000',

              // zIndex: 100000,
            }}
          >
            <div
              style={{
                ...styles.tabs,
                borderBottom: openTab === 0 ? '2px solid' : '0px solid',
                color: openTab === 0 ? '#122857' : 'black',
                fontWeight: openTab === 0 ? 'bold' : 'normal',
              }}
              onClick={() => {
                setOpenTab(0);
              }}
            >
              Contacts
              {contactsUnreadCount > 0 && <div style={styles.redCircle} />}
            </div>
            <div
              style={{
                ...styles.tabs,
                borderBottom: openTab === 1 ? '2px solid' : '0px solid',
                color: openTab === 1 ? '#122857' : 'black',
                fontWeight: openTab === 1 ? 'bold' : 'normal',
              }}
              onClick={() => {
                setOpenTab(1);
              }}
            >
              Following
              {followingUnreadCount > 0 && <div style={styles.redCircle} />}
            </div>
            {isAttendee() && (
              <div
                style={{
                  ...styles.tabs,
                  borderBottom: openTab === 2 ? '2px solid' : '0px solid',
                  color: openTab === 2 ? '#122857' : 'black',
                  fontWeight: openTab === 2 ? 'bold' : 'normal',
                }}
                onClick={() => {
                  setOpenTab(2);
                }}
              >
                Directory
              </div>
            )}
          </div>

          {openTab === 0 &&
            (directMessages?.length > 0 ? (
              <div style={styles.chatRoom}>
                {directMessages?.map((conversation, idx) => (
                  <SidebarConversation
                    key={`conversation_sidebar_${conversation?.client?.sid}`}
                    conversation={conversation}
                    page={page}
                    isDirectMessage={true}
                    onClick={undefined}
                  />
                ))}
              </div>
            ) : (
              <div style={styles.emptyRooms}>
                You do not have any 1 to 1 connections yet. Reach out to other
                users to start a conversation!
              </div>
            ))}
          {openTab === 1 &&
            (openRooms?.length > 0 ? (
              <div style={styles.chatRoom}>
                {openRooms?.map((conversation, idx) => (
                  <SidebarConversation
                    key={`group_conversation_sidebar_${conversation?.client?.sid}`}
                    conversation={conversation}
                    page={page}
                    isDirectMessage={false}
                    onClick={undefined}
                  />
                ))}
              </div>
            ) : (
              <div style={styles.emptyRooms}>
                You have not joined any ongoing discussions. Visit rooms and
                join the conversation!
              </div>
            ))}
          {openTab === 2 && (
            <div style={styles.chatRoom}>
              <div style={{ height: '100%', width: '100%' }}>
                <ChatDirectory page={page} />
              </div>
            </div>
          )}
          {/* <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              paddingLeft: 10,
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
              alignItems: 'center',
            }}
          >
            <div
              onClick={() => {
                setIsOpenRooms(!isOpenRooms);
              }}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {!isOpenRooms ? (
                <ArrowRightIcon
                  style={{ cursor: 'pointer', color: '#757575' }}
                />
              ) : (
                <ArrowDropDownIcon
                  style={{ cursor: 'pointer', color: '#757575' }}
                />
              )}
            </div>
            <div style={styles.roomBanner}>
              Subscribed Rooms{' '}
              {openRoomUnreadCount > 0 && <span>({openRoomUnreadCount})</span>}
            </div>
          </div> */}
        </div>
      </div>
      {/* <div> */}
      <a title="New Chat">
        <div
          style={{
            ...styles.circle,
            position: 'absolute',
            bottom: 10,
            right: 10,
            cursor: 'pointer',
          }}
          onClick={() => {
            setIsCreateNewMessage(true);
            dispatch(setEngagement('newMessage'));
          }}
        >
          <SmsIcon
            style={{ width: 18, height: 18, color: 'white', cursor: 'pointer' }}
          />
        </div>
      </a>

      {/* </div> */}
    </div>
  );
}

const styles = {
  root: {
    display: 'flex',
    position: 'fixed',
    right: 10,
    bottom: 60,
    height: 50,
    width: 300,
    borderRadius: 50,
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: '#122857',
    color: 'white',
    padding: 10,

    boxShadow: '0 0 15px -7px #000',
    zIndex: 100000,
  },
  chatRoot: {
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    height: '100%',
  },
  header: {
    width: '100%',
    height: 50,
    backgroundColor: '#122857',
    color: 'white',
    display: 'flex',
    // justifyContent: 'center',
    padding: 15,
    alignItems: 'center',
    flexDirection: 'row',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    position: 'relative',
  },
  tabs: {
    margin: 5,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 5,
    marginRight: 15,
    marginBottom: 0,
    borderColor: '#122857',
    cursor: 'pointer',
    position: 'relative',
  },
  roomBanner: {
    width: '100%',
    // backgroundColor: 'gray',
    // color: 'white',
    padding: 5,
    paddingLeft: 5,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  chatRoom: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingTop: 5,
    // height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    // paddingLeft: 15,
    // paddingRight: 15,
    alignItems: 'center',
    justifyContent: 'space-between',
    // borderBottom: '1.5px solid rgba(0, 0, 0, 0.12)',
    // boxShadow: '0 0 15px -7px #000',
  },
  circle: {
    borderRadius: 50,
    width: 40,
    height: 40,
    backgroundColor: '#122857',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,

    boxShadow: '0 0 15px -7px #000',
    zIndex: 100000,
  },
  emptyRooms: {
    padding: 20,
  },
  redCircle: {
    width: 6,
    height: 6,
    borderRadius: 5,
    backgroundColor: 'red',
    position: 'absolute',
    top: 1,
    right: -1,
  },
};
