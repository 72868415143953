import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';

// material ui
import { Button, Grid, TextField, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ToggleButton from '@material-ui/lab/ToggleButton';
import CheckIcon from '@material-ui/icons/Check';
import Hidden from '@material-ui/core/Hidden';
import DialogWrapper from '../../Components/DialogWrapper';
import Divider from '@material-ui/core/Divider';

// components
import NavBar from '../../Components/Navbar';
import Privacy from '../Privacy';
import {
  landingPage,
  eventId,
  accountId,
  allowedRegistrationDomains,
  allowedSigninDomains,
  MerckSSOEnabled,
  OktaSSOEnabled,
  landingpageOnly,
} from '../../config';
import {
  Link,
  // Redirect,
  withRouter,
} from 'react-router-dom';
import trackAction from '../../Store/Actions/tracker';
import { EmailTemplate, FormatObject } from './EmailTemplate';
import { fieldNameAlias, countryList } from './FieldHelperData';
import CompanyLookupField from './CompanyLookupField';
import JobTitleLookupField from './JobTitleLookupField';

// helper data
import { merckSites } from './MerckInfo';
import { sanofiSites } from './SanofiInfo';

// redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setAuthenticatedUser from '../../Store/Actions/setUser';
import setBriefcase from '../../Store/Actions/setBriefcase';
import setEventDetails from '../../Store/Actions/setEventDetails';
import { setLoginRedirect } from '../../Store/Reducers/user';
import { queryApi } from '../../Services/queryApi';

const useStyles = (theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      width: '100%',
    },
  },
  gridBody: {
    marginTop: '60px',
  },
  bannerImage: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    position: 'relative',
    padding: 0,
    margin: 0,
    top: '-4px',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginBottom: '80px',
  },
  formControl: {
    // margin: theme.spacing(2),
    minWidth: 120,
    width: '80%',
  },
  formControlButton: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '60%',
  },
  formControlDropdown: {
    // margin: theme.spacing(2),
    minWidth: 120,
    width: '100%',
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
  toggleButton: {
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.light,
    },
    '& .Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  acceptButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    width: '40%',
    marginLeft: '5%',
    marginRight: '5%',
    fontSize: '1rem',
  },
  closeButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.light,
    width: '40%',
    marginLeft: '5%',
    marginRight: '5%',
    fontSize: '1rem',
  },
  privacyButton: {
    position: 'fixed',
    bottom: '16px',
    right: '16px',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
});

class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ssoError: false,
      ssoSignedIn: false,
      ssoEmailChecked: false,
      code: null,
      // global state
      formSubmitted: false,
      globalError: false,
      submissionSuccess: false,
      submissionFailure: false,
      mustConfirmEmail: false,
      justSignedUp: false,
      federatedId: false,
      emailChecked: false,
      existingZohoRegistrant: false,
      existingCognitoUser: false,

      // registration fields
      registrationFields: [],

      // login
      newPasswordRequired: false,
      newPassword: '',

      // password
      password: '',
      passwordError: null,
      passwordErrorMessage: '',

      // privacy
      privacySelected: false,
      privacyOpen: false,
      privacyAgree: false,

      // first name
      firstName: '',
      firstNameError: null,
      firstNameErrorMessage: '',

      // last name
      lastName: '',
      lastNameError: null,
      lastNameErrorMessage: '',

      // email
      email: '',
      emailError: null,
      emailErrorMessage: '',

      // department
      department: '',
      departmentError: null,
      departmentErrorMessage: '',

      // division
      division: '',
      divisionError: null,
      divisionErrorMessage: '',

      // job title
      jobTitle: '',
      jobTitleError: null,
      jobTitleErrorMessage: '',

      // company
      company: '',
      companyError: null,
      companyErrorMessage: '',

      // site
      site: '',
      siteError: null,
      siteErrorMessage: '',

      // phoneNumber
      phoneNumber: '',
      phoneNumberError: null,
      phoneNumberErrorMessage: '',

      // addressStreet
      addressStreet: '',
      addressStreetError: null,
      addressStreetErrorMessage: '',

      // addressCity
      addressCity: '',
      addressCityError: null,
      addressCityErrorMessage: '',

      // addressState
      addressState: '',
      addressStateError: null,
      addressStateErrorMessage: '',

      // addressZip
      addressZip: '',
      addressZipError: null,
      addressZipErrorMessage: '',

      // country
      addressCountry: '',
      addressCountryError: null,
      addressCountryErrorMessage: '',

      // attendance
      attendance: '',
      attendanceError: null,
      attendanceErrorMessage: '',

      // custom1
      custom1: '',
      custom1Error: null,
      custom1ErrorMessage: '',

      // custom2
      custom2: '',
      custom2Error: null,
      custom2ErrorMessage: '',

      // custom3
      custom3: '',
      custom3Error: null,
      custom3ErrorMessage: '',

      // custom4
      custom4: '',
      custom4Error: null,
      custom4ErrorMessage: '',

      // custom5
      custom5: '',
      custom5Error: null,
      custom5ErrorMessage: '',

      // custom6
      custom6: '',
      custom6Error: null,
      custom6ErrorMessage: '',

      // question1
      question1: '',
      question1Error: null,
      question1ErrorMessage: '',

      // question2
      question2: '',
      question2Error: null,
      question2ErrorMessage: '',

      // question3
      question3: '',
      question3Error: null,
      question3ErrorMessage: '',

      // question4
      question4: '',
      question4Error: null,
      question4ErrorMessage: '',

      // find out
      findOut: '',
      findOutError: null,
      findOutErrorMessage: '',

      // get out
      getOut: '',
      getOutError: null,
      getOutErrorMessage: '',

      // companies of interest
      companiesOfInterest: [],
    };
  }

  async componentDidMount() {
    if (this.props.location.search) {
      const query = new URLSearchParams(this.props.location.search);
      for (let param of query.entries()) {
        if (param[0] === 'error') {
          this.setState({
            ssoError: true,
          });
        }
      }
    }
    let user;
    await Auth.currentSession()
      .then((response) => {
        user = response;
      })
      .catch((error) => {
        console.log(error);
      });

    if (user) {
      await this.ssoCheckRegistrationStatus(user.idToken.payload.email);

      //  await this.trackSignIn()
      //  await this.props.setEventDetails()
      //  await this.props.setAuthenticatedUser()
      //  await this.props.setBriefcase()

      // //  window.open('/lobby')
    }
  }

  trackSignIn() {
    const event = {
      action: 'Entered',
      resource_type: 'VirtualEnvironment',
      resource_id: null,
      url_visited: '/registration',
      resource_account_id: null,
      resource_account_name: null,
      resource_name: null,
    };

    this.props.trackAction(event);
  }

  handleChange(event, inputIdentifier) {
    this.setState({ [inputIdentifier]: event.target.value });
  }

  handleZohoRegistration = async () => {
    // check to see the user entered everything required
    let allowedRegistrationDomainsDynamic;
    let allowedRegistrationDomainsDb;
    if (this.props.event?.data.allowed_registration_domains) {
      allowedRegistrationDomainsDb = JSON.parse(
        this.props.event.data.allowed_registration_domains
      );
    }
    if (!allowedRegistrationDomainsDb) {
      allowedRegistrationDomainsDynamic = allowedRegistrationDomains;
    } else {
      allowedRegistrationDomainsDynamic = allowedRegistrationDomainsDb;
    }
    this.setState({
      privacyAgree: false,
      globalError: false,
      emailError: null,
      emailErrorMessage: '',
      firstNameError: null,
      firstNameErrorMessage: '',
      lastNameError: null,
      lastNameErrorMessage: '',
      jobTitleError: null,
      jobTitleErrorMessage: '',
      companyError: null,
      companyErrorMessage: '',
      departmentError: null,
      departmentErrorMessage: '',
      divisionError: null,
      divisionErrorMessage: '',
      siteError: null,
      siteErrorMessage: '',
      addressStreetError: null,
      addressStreetErrorMessage: '',
      addressCityError: null,
      addressCityErrorMessage: '',
      addressStateError: null,
      addressStateErrorMessage: '',
      addressZipError: null,
      addressZipErrorMessage: '',
      addressCountryError: null,
      addressCountryErrorMessage: '',
      phoneNumberError: null,
      phoneNumberErrorMessage: '',
      custom1Error: null,
      custom1ErrorMessage: '',
      custom2Error: null,
      custom2ErrorMessage: '',
      custom3Error: null,
      custom3ErrorMessage: '',
      custom4Error: null,
      custom4ErrorMessage: '',
      custom5Error: null,
      custom5ErrorMessage: '',
      custom6Error: null,
      custom6ErrorMessage: '',
      question1Error: null,
      question1ErrorMessage: '',
      question2Error: null,
      question2ErrorMessage: '',
      question3Error: null,
      question3ErrorMessage: '',
      question4Error: null,
      question4ErrorMessage: '',
      attendanceError: null,
      attendanceErrorMessage: '',
    });

    let validation = true;

    if (!this.state.privacySelected) {
      validation = false;

      this.setState({
        privacyAgree: true,
        globalError: true,
      });
    }

    if (this.state.email.indexOf(' ') > 0) {
      validation = false;

      this.setState({
        emailError: true,
        emailErrorMessage: 'Email cannot contain spaces',
        globalError: true,
      });
    }

    if (!this.state.email) {
      validation = false;
      this.setState({
        emailError: true,
        emailErrorMessage: 'Email is required',
        globalError: true,
      });
    }

    if (!this.state.email.includes('@') || !this.state.email.includes('.')) {
      validation = false;
      this.setState({
        emailError: true,
        emailErrorMessage: 'Email must contain:  @ and .',
        globalError: true,
      });
    }

    if (!this.state.firstName) {
      validation = false;
      this.setState({
        firstNameError: true,
        firstNameErrorMessage: 'First name is required',
        globalError: true,
      });
    }

    if (!this.state.lastName) {
      validation = false;
      this.setState({
        lastNameError: true,
        lastNameErrorMessage: 'Last name is required',
        globalError: true,
      });
    }

    if (!this.state.department) {
      if (
        this.state.registrationFields[3].filter(
          (field) => field.label === 'Department'
        ).length > 0
      ) {
        validation = false;
        this.setState({
          departmentError: true,
          departmentErrorMessage: 'Department is required',
          globalError: true,
        });
      }
    }

    if (this.state.department.length > 48) {
      validation = false;
      this.setState({
        departmentError: true,
        departmentErrorMessage:
          'Department name is too long. Must be less than 48 characters.',
        globalError: true,
      });
    }

    if (!this.state.division) {
      if (
        this.state.registrationFields[3].filter(
          (field) => field.label === 'Division'
        ).length > 0
      ) {
        validation = false;
        this.setState({
          divisionError: true,
          divisionErrorMessage: 'Division is required',
          globalError: true,
        });
      }
    }

    if (!this.state.jobTitle) {
      if (
        this.state.registrationFields[3].filter(
          (field) => field.label === 'Job Title'
        ).length > 0
      ) {
        validation = false;
        this.setState({
          jobTitleError: true,
          jobTitleErrorMessage: 'Job Title is required',
          globalError: true,
        });
      }
    }

    if (!this.state.company) {
      if (
        this.state.registrationFields[3].filter(
          (field) => field.label === 'Company'
        ).length > 0
      ) {
        validation = false;
        this.setState({
          companyError: true,
          companyErrorMessage: 'Company is required',
          globalError: true,
        });
      }
    }

    let emailHasRequiredDomain = false;

    for (let i = 0; i < allowedRegistrationDomainsDynamic.length; i++) {
      if (
        this.state.email
          .toLowerCase()
          .includes(allowedRegistrationDomainsDynamic[i])
      ) {
        emailHasRequiredDomain = true;
      }
    }

    if (!emailHasRequiredDomain) {
      validation = false;
      this.setState({
        emailError: true,
        emailErrorMessage:
          'User is now allowed at this event. Please reach out to your Booth Admin to be added or contact Support below.',
        globalError: true,
      });
    }

    if (!this.state.site) {
      if (
        this.state.registrationFields[3].filter(
          (field) => field.label === 'Site'
        ).length > 0
      ) {
        validation = false;
        this.setState({
          siteError: true,
          siteErrorMessage: 'Site is required',
          globalError: true,
        });
      }
    }

    if (!this.state.addressStreet) {
      if (
        this.state.registrationFields[3].filter(
          (field) => field.label === 'Street Address'
        ).length > 0
      ) {
        validation = false;
        this.setState({
          addressStreetError: true,
          addressStreetErrorMessage: 'Address is required',
          globalError: true,
        });
      }
    }

    if (!this.state.addressCity) {
      if (
        this.state.registrationFields[3].filter(
          (field) => field.label === 'City'
        ).length > 0
      ) {
        validation = false;
        this.setState({
          addressCityError: true,
          addressCityErrorMessage: 'City is required',
          globalError: true,
        });
      }
    }

    if (!this.state.addressState) {
      if (
        this.state.registrationFields[3].filter(
          (field) => field.label === 'State'
        ).length > 0
      ) {
        validation = false;
        this.setState({
          addressStateError: true,
          addressStateErrorMessage: 'State is required',
          globalError: true,
        });
      }
    }

    if (!this.state.addressZip) {
      if (
        this.state.registrationFields[3].filter(
          (field) => field.label === 'Zip'
        ).length > 0
      ) {
        validation = false;
        this.setState({
          addressZipError: true,
          addressZipErrorMessage: 'Postal Code is required',
          globalError: true,
        });
      }
    }

    if (!this.state.addressCountry) {
      if (
        this.state.registrationFields[3].filter(
          (field) => field.label === 'Country'
        ).length > 0
      ) {
        validation = false;
        this.setState({
          addressCountryError: true,
          addressCountryErrorMessage: 'Country is required',
          globalError: true,
        });
      }
    }

    if (!this.state.phoneNumber) {
      if (
        this.state.registrationFields[3].filter(
          (field) => field.label === 'Phone Number'
        ).length > 0
      ) {
        validation = false;
        this.setState({
          phoneNumberError: true,
          phoneNumberErrorMessage: 'Phone Number is required',
          globalError: true,
        });
      }
    }

    if (!this.state.attendance) {
      if (
        this.state.registrationFields[3].filter(
          (field) => field.label === 'Attendance'
        ).length > 0
      ) {
        validation = false;
        this.setState({
          attendanceError: true,
          attendanceErrorMessage: 'Attendance is required',
          globalError: true,
        });
      }
    }

    if (!this.state.custom1) {
      if (
        this.state.registrationFields[3].filter(
          (field) => field.label === 'Custom 1'
        ).length > 0
      ) {
        validation = false;
        this.setState({
          custom1Error: true,
          custom1ErrorMessage: `${
            this.state.registrationFields[3].filter(
              (field) => field.label === 'Custom 1'
            )[0].name
          } is required`,
          globalError: true,
        });
      }
    }
    if (!this.state.custom2) {
      if (
        this.state.registrationFields[3].filter(
          (field) => field.label === 'Custom 2'
        ).length > 0
      ) {
        validation = false;
        this.setState({
          custom2Error: true,
          custom2ErrorMessage: `${
            this.state.registrationFields[3].filter(
              (field) => field.label === 'Custom 2'
            )[0].name
          } is required`,
          globalError: true,
        });
      }
    }
    if (!this.state.custom3) {
      if (
        this.state.registrationFields[3].filter(
          (field) => field.label === 'Custom 3'
        ).length > 0
      ) {
        validation = false;
        this.setState({
          custom3Error: true,
          custom3ErrorMessage: `${
            this.state.registrationFields[3].filter(
              (field) => field.label === 'Custom 3'
            )[0].name
          } is required`,
          globalError: true,
        });
      }
    }
    if (!this.state.custom4) {
      if (
        this.state.registrationFields[3].filter(
          (field) => field.label === 'Custom 4'
        ).length > 0
      ) {
        validation = false;
        this.setState({
          custom4Error: true,
          custom4ErrorMessage: `${
            this.state.registrationFields[3].filter(
              (field) => field.label === 'Custom 4'
            )[0].name
          } is required`,
          globalError: true,
        });
      }
    }
    if (!this.state.custom5) {
      if (
        this.state.registrationFields[3].filter(
          (field) => field.label === 'Custom 5'
        ).length > 0
      ) {
        validation = false;
        this.setState({
          custom5Error: true,
          custom5ErrorMessage: `${
            this.state.registrationFields[3].filter(
              (field) => field.label === 'Custom 5'
            )[0].name
          } is required`,
          globalError: true,
        });
      }
    }
    if (!this.state.custom6) {
      if (
        this.state.registrationFields[3].filter(
          (field) => field.label === 'Custom 6'
        ).length > 0
      ) {
        validation = false;
        this.setState({
          custom6Error: true,
          custom6ErrorMessage: `${
            this.state.registrationFields[3].filter(
              (field) => field.label === 'Custom 6'
            )[0].name
          } is required`,
          globalError: true,
        });
      }
    }

    if (!this.state.question1) {
      if (
        this.state.registrationFields[3].filter(
          (field) => field.label === 'Question 1'
        ).length > 0
      ) {
        validation = false;
        this.setState({
          question1Error: true,
          question1ErrorMessage: 'Answer is required',
          globalError: true,
        });
      }
    }
    if (!this.state.question2) {
      if (
        this.state.registrationFields[3].filter(
          (field) => field.label === 'Question 2'
        ).length > 0
      ) {
        validation = false;
        this.setState({
          question2Error: true,
          question2ErrorMessage: 'Answer is required',
          globalError: true,
        });
      }
    }
    if (!this.state.question3) {
      if (
        this.state.registrationFields[3].filter(
          (field) => field.label === 'Question 3'
        ).length > 0
      ) {
        validation = false;
        this.setState({
          question3Error: true,
          question3ErrorMessage: 'Answer is required',
          globalError: true,
        });
      }
    }
    if (!this.state.question4) {
      if (
        this.state.registrationFields[3].filter(
          (field) => field.label === 'Question 4'
        ).length > 0
      ) {
        validation = false;
        this.setState({
          question4Error: true,
          question4ErrorMessage: 'Answer is required',
          globalError: true,
        });
      }
    }
    if (validation) {
      this.setState({
        formSubmitted: true,
        emailError: false,
        emailErrorMessage: '',
        globalError: false,
      });

      let postedAttendeeId = null;

      let merckAccountId = '1691992000015906075';
      let pciAccountId = '1691992000000189008';
      let isPCI = this.state.email.toLowerCase().includes('@planetconnect.com');

      let stringToArray = (string) => {
        if (string.includes(';')) {
          return string.slice(0, -1).split(';').sort();
        } else {
          return string.slice(0, -1).split(',').sort();
        }
      };

      let attendance = this.state.attendance;
      if (!attendance.includes('Virtual')) {
        attendance = attendance + 'Virtual,';
      }

      await axios({
        method: 'POST',
        url: '/zoho/show-attendees/',
        data: {
          email: this.state.email.toLowerCase(),
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          department: this.state.department,
          division: this.state.division,
          jobTitle: this.state.jobTitle,
          company: this.state.company,
          site: this.state.site,
          businessAddress: this.state.addressStreet,
          city: this.state.addressCity,
          state: this.state.addressState,
          postalCode: this.state.addressZip,
          country: this.state.addressCountry,
          phone: this.state.phoneNumber,
          days: stringToArray(attendance),
          // notes: `${this.state.findOut},${this.state.getOut}`,
          showId: eventId,
          accountId:
            this.state.ssoSignedIn && MerckSSOEnabled
              ? merckAccountId
              : isPCI
              ? pciAccountId
              : accountId,
        },
      })
        .then((response) => {
          postedAttendeeId = response.data.details.id;
          this.setState({
            existingZohoRegistrant: true,
            formSubmitted: false,
          });
        })
        .catch((error) => {
          this.setState({
            existingZohoRegistrant: false,
            formSubmitted: false,
            globalError: true,
            emailError: true,
            emailErrorMessage:
              'Email is not valid for registration. Please contact support.',
          });
        });

      let checkForMulti = (field, string) => {
        if (field?.type === 'multi_select') {
          return stringToArray(string);
        } else {
          return string;
        }
      };

      let customFieldAnswers = [
        {
          attendee_id: postedAttendeeId,
          value: this.state.custom1
            ? checkForMulti(
                this.state.registrationFields[1][0],
                this.state.custom1
              )
            : null,
          attribute: this.state.registrationFields[1][0]
            ? this.state.registrationFields[1][0].name
            : null,
        },
        {
          attendee_id: postedAttendeeId,
          value: this.state.custom2
            ? checkForMulti(
                this.state.registrationFields[1][1],
                this.state.custom2
              )
            : null,
          attribute: this.state.registrationFields[1][1]
            ? this.state.registrationFields[1][1].name
            : null,
        },
        {
          attendee_id: postedAttendeeId,
          value: this.state.custom3
            ? checkForMulti(
                this.state.registrationFields[1][3],
                this.state.custom3
              )
            : null,
          attribute: this.state.registrationFields[1][2]
            ? this.state.registrationFields[1][2].name
            : null,
        },
        {
          attendee_id: postedAttendeeId,
          value: this.state.custom4
            ? checkForMulti(
                this.state.registrationFields[1][3],
                this.state.custom4
              )
            : null,
          attribute: this.state.registrationFields[1][3]
            ? this.state.registrationFields[1][3].name
            : null,
        },
        {
          attendee_id: postedAttendeeId,
          value: this.state.custom5
            ? checkForMulti(
                this.state.registrationFields[1][4],
                this.state.custom5
              )
            : null,
          attribute: this.state.registrationFields[1][4]
            ? this.state.registrationFields[1][4].name
            : null,
        },
        {
          attendee_id: postedAttendeeId,
          value: this.state.custom6
            ? checkForMulti(
                this.state.registrationFields[1][5],
                this.state.custom6
              )
            : null,
          attribute: this.state.registrationFields[1][5]
            ? this.state.registrationFields[1][5].name
            : null,
        },
      ];
      let customQuestionAnswers = [
        {
          attendee_id: postedAttendeeId,
          value: this.state.question1
            ? checkForMulti(
                this.state.registrationFields[2][0],
                this.state.question1
              )
            : null,
          attribute: this.state.registrationFields[2][0]
            ? this.state.registrationFields[2][0].name
            : null,
        },
        {
          attendee_id: postedAttendeeId,
          value: this.state.question2
            ? checkForMulti(
                this.state.registrationFields[2][1],
                this.state.question2
              )
            : null,
          attribute: this.state.registrationFields[2][1]
            ? this.state.registrationFields[2][1].name
            : null,
        },
        {
          attendee_id: postedAttendeeId,
          value: this.state.question3
            ? checkForMulti(
                this.state.registrationFields[2][2],
                this.state.question3
              )
            : null,
          attribute: this.state.registrationFields[2][2]
            ? this.state.registrationFields[2][2].name
            : null,
        },
        {
          attendee_id: postedAttendeeId,
          value: this.state.question4
            ? checkForMulti(
                this.state.registrationFields[2][3],
                this.state.question4
              )
            : null,
          attribute: this.state.registrationFields[2][3]
            ? this.state.registrationFields[2][3].name
            : null,
        },
      ];
      let companiesOfInterest = {
        attendee_id: postedAttendeeId,
        value: this.state.companiesOfInterest,
        attribute: 'Suppliers/Exhibitors of Interest',
      };

      for await (let customField of customFieldAnswers) {
        if (customField.attribute) {
          await axios({
            method: 'POST',
            url: `/registration-custom/${postedAttendeeId}`,
            data: customField,
          });
        }
      }

      for await (let customQuestion of customQuestionAnswers) {
        if (customQuestion.attribute) {
          await axios({
            method: 'POST',
            url: `/registration-custom/${postedAttendeeId}`,
            data: customQuestion,
          });
        }
      }
      if (this.state.companiesOfInterest.length) {
        await axios({
          method: 'POST',
          url: `/registration-custom/${postedAttendeeId}`,
          data: companiesOfInterest,
        });
      }
      let toEmail = [
        `${this.state.firstName} ${this.state.lastName}
        <${this.state.email.toLowerCase()}>`,
        'registration@planetconnect.com',
        // 'rjanelli@planetconnect.com',
      ];
      let subject = `${this.props.event.data.title} Registration confirmation email`;

      let userInformation = {
        name: `${this.state.firstName} ${this.state.lastName}`,
        department: this.state.department,
        division: this.state.division,
        jobTitle: this.state.jobTitle,
        site: this.state.site,
        businessAddress: this.state.addressStreet,
        city: this.state.addressCity,
        state: this.state.addressState,
        postalCode: this.state.addressZip,
        country: this.state.addressCountry,
        [customFieldAnswers[0].attribute]: customFieldAnswers[0].value,
        [customFieldAnswers[1].attribute]: customFieldAnswers[1].value,
        [customFieldAnswers[2].attribute]: customFieldAnswers[2].value,
        [customFieldAnswers[3].attribute]: customFieldAnswers[3].value,
        [customFieldAnswers[4].attribute]: customFieldAnswers[4].value,
        [customFieldAnswers[5].attribute]: customFieldAnswers[5].value,
        [customQuestionAnswers[0].attribute]: customQuestionAnswers[0].value,
        [customQuestionAnswers[1].attribute]: customQuestionAnswers[1].value,
        [customQuestionAnswers[2].attribute]: customQuestionAnswers[2].value,
        [customQuestionAnswers[3].attribute]: customQuestionAnswers[3].value,
        'suppliers /ExhibitorsOfInterest':
          this.state.companiesOfInterest.join(', '),
        // companiesOfInterest: this.state.companiesOfInterest.join(', '),
        attendance: stringToArray(this.state.attendance),
      };

      // remove blank or unusued fields so they don't show in email
      userInformation = Object.keys(userInformation)
        .filter((k) => userInformation[k] !== '')
        .filter((k) => userInformation[k] !== null)
        .reduce((a, k) => ({ ...a, [k]: userInformation[k] }), {});

      let body1 = `We have received your Registration for ${this.props.event.data.title}. Below is the registration information we have received:`;
      let body2 = FormatObject(userInformation);
      let body3 = `You can return to ${this.props.event.data.landing_page_url} to view additional information about the event.`;
      // 'If you would now like to submit an abstract for consideration, please revisit ${this.props.event.data.landing_page_url} and click the Abstract Submission Link.';
      let body4 = `If you would like to update any information or cancel your registration, just reply to this message and put any updates you would like to see at the top of the message content. 
      If you have any questions about this event, you can contact PlanetConnect at eregistration@planetconnect.com.  Look for additional Information emailed to you about entering the Virtual Environment and details about the event.`;
      // let body4 = `You can access the virtual environment at ${this.props.event.data.landing_page_url}`;
      let body5 = 'Thank you for registering.';

      let body = `${body1}<br/>${body2}<br/>${body3}<br/>${body4}<br/>${body5}`;

      let emailObject = {
        to: toEmail,
        subject: subject,
        body: body,
        title: '',
        // title: userInformation.name,
        banner: this.props.event.data.banner_location,
      };

      let bodyOfEmail = EmailTemplate(emailObject);

      emailObject.body = bodyOfEmail;

      await axios({
        method: 'POST',
        url: `/mail`,
        data: emailObject,
      });
    }
  };

  handleValidation = () => {
    this.setState({
      formSubmitted: true,
    });

    Auth.confirmSignUp(
      this.state.email.toLowerCase(),
      this.state.confirmationCode
    )
      .then((response) => {
        this.setState({
          userConfirmed: true,
          formSubmitted: false,
        });
      })
      .catch((error) => {
        this.setState({
          confirmationCodeError: true,
          confirmationCodeErrorMessage: 'Incorrect Confirmation Code',
          formSubmitted: false,
        });
      });
  };

  handleModal(value, inputIdentifier) {
    this.setState({ [inputIdentifier]: value });
  }

  checkEmail = async () => {
    let allowedSigninDomainsDynamic;
    let allowedSigninDomainsDb;
    if (this.props.event?.data.allowed_signin_domains) {
      allowedSigninDomainsDb = JSON.parse(
        this.props.event.data.allowed_signin_domains
      );
    }

    if (!allowedSigninDomainsDb) {
      allowedSigninDomainsDynamic = allowedSigninDomains;
    } else {
      allowedSigninDomainsDynamic = allowedSigninDomainsDb;
    }

    this.setState({
      formSubmitted: true,
      emailError: false,
      emailErrorMessage: '',
      globalError: false,
    });

    if (!this.state.email) {
      this.setState({
        formSubmitted: false,
        emailError: true,
        emailErrorMessage: 'Please enter a valid email',
        globalError: true,
      });

      return;
    }

    // if (this.state.email.includes('+')) {
    //   this.setState({
    //     formSubmitted: false,
    //     emailError: true,
    //     emailErrorMessage: "Email cannot contain a '+'",
    //     globalError: true,
    //   });

    //   return;
    // }

    if (!this.state.email.includes('@') || !this.state.email.includes('.')) {
      this.setState({
        formSubmitted: false,
        emailError: true,
        emailErrorMessage: 'Email must contain: @ and .',
        globalError: true,
      });

      return;
    }

    if (this.state.email.includes(' ')) {
      this.setState({
        formSubmitted: false,
        emailError: true,
        emailErrorMessage: 'Email cannot contain a space',
        globalError: true,
      });

      return;
    }

    //logic to be used if we need to check the domain of the user's email on enterance
    let emailHasRequiredDomain = false;

    for (let i = 0; i < allowedSigninDomainsDynamic.length; i++) {
      if (
        this.state.email.toLowerCase().includes(allowedSigninDomainsDynamic[i])
      ) {
        emailHasRequiredDomain = true;
      }
    }

    if (!emailHasRequiredDomain) {
      this.setState({
        formSubmitted: false,
        emailError: true,
        emailErrorMessage: 'Your user is not allowed at this event',
        globalError: true,
      });

      return;
    }

    let isMerckDomain = false;
    let merckDomains = ['@merck', '@msd'];

    for (let i = 0; i < merckDomains.length; i++) {
      if (this.state.email.toLowerCase().includes(merckDomains[i])) {
        isMerckDomain = true;
      }
    }

    if (isMerckDomain) {
      this.props.history.push('/authentication');
      // this.setState({
      //   formSubmitted: false,
      //   emailError: true,
      //   emailErrorMessage:
      //     'Please use the Secure Login button on the home page to enter',
      //   globalError: true,
      // });

      return;
    }

    let existingZohoRegistrant = false;
    let existingCognitoUser = false;
    // check to see if they are already a cognito user

    const existingRegistrant = await axios({
      method: 'GET',
      url: `/show-attendees/${eventId}/${this.state.email.toLowerCase()}`,
    });

    if (existingRegistrant.data.length) {
      if (
        existingRegistrant.data[0].email.toLowerCase() ===
        this.state.email.toLowerCase()
      ) {
        existingZohoRegistrant = true;
      }
    } else {
      existingZohoRegistrant = false;
    }

    // check if attendee exists using existingZohoRegistrant
    let allowedRegistrationDomainsDynamic;
    let allowedRegistrationDomainsDb;
    if (this.props.event?.data?.allowed_registration_domains) {
      allowedRegistrationDomainsDb = JSON.parse(
        this.props.event.data.allowed_registration_domains
      );
    }
    if (!allowedRegistrationDomainsDb) {
      allowedRegistrationDomainsDynamic = allowedRegistrationDomains;
    } else {
      allowedRegistrationDomainsDynamic = allowedRegistrationDomainsDb;
    }

    if (!existingZohoRegistrant) {
      let emailHasRequiredDomain = false;

      for (let i = 0; i < allowedRegistrationDomainsDynamic.length; i++) {
        if (
          this.state.email
            .toLowerCase()
            .includes(allowedRegistrationDomainsDynamic[i])
        ) {
          emailHasRequiredDomain = true;
        }
      }
      // prevent going to registration page if email doesn't check out
      if (!emailHasRequiredDomain) {
        this.setState({
          formSubmitted: false,
          emailError: true,
          emailErrorMessage:
            'User is now allowed at this event. Please reach out to your Booth Admin to be added or contact Support below.',
          globalError: true,
        });
        return;
      }
    }

    const registrationFields = await axios({
      method: 'get',
      url: 'forms/' + eventId,
      // headers: { idtoken: data.idToken.jwtToken },
      params: { showId: eventId },
    })
      .then((response) => {
        let formObject = response.data.data;
        let registration = formObject.filter(
          (item) => item.type === 'registration'
        )[0];
        registration.fields = registration.fields
          .sort(function (a, b) {
            return a.field_id - b.field_id;
          })
          .filter((field) => field.is_enabled === true);
        let standardFields = registration.fields.filter(
          (field) => field.section === 'fieldsStandard'
        );
        let customFields = registration.fields.filter(
          (field) => field.section === 'fieldsCustom'
        );
        let questions = registration.fields.filter(
          (field) => field.section === 'question'
        );
        let requiredFields = registration.fields.filter(
          (field) => field.is_required === true
        );
        let multiSelectFields = registration.fields.filter(
          (field) => field.type === 'multi_select'
        );

        return [
          standardFields,
          customFields,
          questions,
          requiredFields,
          multiSelectFields,
        ];
      })
      .catch((error) => console.log(error));

    const code = '000000';

    await Auth.confirmSignUp(this.state.email.toLowerCase(), code, {
      forceAliasCreation: false,
    })
      .then((data) => console.log(data))
      .catch((err) => {
        // console.log(err, err.code);
        switch (err.code) {
          case 'UserNotFoundException':
            existingCognitoUser = false;
            break;
          case 'NotAuthorizedException':
            existingCognitoUser = true;
            break;
          case 'AliasExistsException':
            existingCognitoUser = true;
            break;
          case 'CodeMismatchException':
            existingCognitoUser = true;
            break;
          case 'ExpiredCodeException':
            existingCognitoUser = true;
            break;
          default:
            existingCognitoUser = false;
            break;
        }
      });

    this.setState({
      formSubmitted: false,
      emailChecked: true,
      existingCognitoUser: existingCognitoUser,
      existingZohoRegistrant: existingZohoRegistrant,
      registrationFields: registrationFields,
    });
  };

  signIn = () => {
    this.setState({
      formSubmitted: true,
    });
    Auth.signIn(this.state.email.toLowerCase(), this.state.password)
      .then(async (response) => {
        if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.setState({
            newPasswordRequired: true,
            formSubmitted: false,
          });
        } else {
          this.trackSignIn();
          this.props.setEventDetails();
          await this.props.setAuthenticatedUser();
          this.props.setBriefcase();
          if (this.props.user?.loginRedirect) {
            const loginRedirect = this.props.user.loginRedirect;
            this.props.setLoginRedirect();
            const redirectUrl = `${loginRedirect.pathname}${loginRedirect.search}`;
            this.props.history.push(
              redirectUrl,
              this.props.user.loginRedirect.state
            );
          } else {
            this.props.history.push('/lobby');
          }
        }
      })
      .catch((error) => {
        if (error.code === 'UserNotConfirmedException') {
          this.setState({
            formSubmitted: false,
            passwordError: true,
            passwordErrorMessage:
              'Please check your email for a confirmation link. If you have not recieved one, please check spam or contact support.',
          });
        } else {
          this.setState({
            formSubmitted: false,
            passwordError: true,
            passwordErrorMessage:
              'Incorrect password. If you forgot your pasword, you may reset it below be clicking on the "Reset Password" button.',
          });
        }
      });
  };

  setPermanentPassword = () => {
    this.setState({
      formSubmitted: true,
    });

    Auth.signIn(this.state.email.toLowerCase(), this.state.password)
      .then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          Auth.completeNewPassword(user, this.state.newPassword)
            .then(async (user) => {
              this.props.setEventDetails();
              await this.props.setAuthenticatedUser();
              if (this.props.user?.loginRedirect) {
                this.props.setLoginRedirect();
                const redirectUrl = `${this.props.user.loginRedirect.path}${
                  this.props.user.loginRedirect.search
                    ? `?${this.props.user.loginRedirect.search}`
                    : ''
                }`;
                this.props.history.push(
                  redirectUrl,
                  this.props.user.loginRedirect.state
                );
              } else {
                this.props.history.replace('/lobby');
              }
            })
            .catch((e) => {
              this.setState({
                emailError: true,
                emailErrorMessage: 'Unable to set permanent password',
                formSubmitted: false,
              });
            });
        } else {
          this.props.setEventDetails();
          this.props.setAuthenticatedUser();
          if (this.props.user?.loginRedirect) {
            const loginRedirect = this.props.user.loginRedirect;
            this.props.setLoginRedirect();
            const redirectUrl = `${loginRedirect.pathname}${loginRedirect.search}`;
            this.props.history.push(
              redirectUrl,
              this.props.user.loginRedirect.state
            );
          } else {
            this.props.history.push('/lobby');
          }
        }
      })
      .catch((e) => {
        this.setState({
          emailError: true,
          emailErrorMessage: 'Unable to set permanent password',
          formSubmitted: false,
        });
      });
  };

  handleCognitoSignup = () => {
    this.setState({
      formSubmitted: true,
      passwordError: false,
      passwordErrorMessage: '',
    });

    Auth.signUp({
      username: this.state.email.toLowerCase(),
      password: this.state.password,
      attributes: {
        email: this.state.email.toLowerCase(),
      },
    })
      .then((response) => {
        this.setState({
          mustConfirmEmail: true,
          existingCognitoUser: true,
          formSubmitted: false,
          justSignedUp: true,
        });
      })
      .catch((error) => {
        switch (error.code) {
          case 'UsernameExistsException':
            this.setState({
              emailError: true,
              emailErrorMessage: 'User already exists',
              formSubmitted: false,
            });
            break;
          case 'InvalidParameterException':
            this.setState({
              emailError: true,
              emailErrorMessage: 'Please ensure you are using a valid email',
              formSubmitted: false,
            });
            break;
          default:
            this.setState({
              passwordError: true,
              passwordErrorMessage:
                'Password must be at least 8 characters long.',
              formSubmitted: false,
            });
        }
      });
  };

  handleChoiceSelect = (event, fieldName) => {
    this.setState({
      [fieldName]: event.target.value,
    });
  };

  handleMultiSelect = (value) => {
    this.setState({
      companiesOfInterest: value,
    });
  };

  populateAddressOnSiteSelect = (event, sites) => {
    let siteName = event.target.value;
    let finalSite = sites.filter((site) => site.site === siteName);
    this.setState({
      addressStreet: finalSite[0].address_1,
      addressCity: finalSite[0].city,
      addressState: finalSite[0].state,
      addressZip: finalSite[0].zip,
      addressCountry: finalSite[0].country,
    });
  };

  ssoSignedInNavigation = async () => {
    await this.trackSignIn();
    await this.props.setEventDetails();
    await this.props.setAuthenticatedUser();
    await this.props.setBriefcase();
    // window.location.reload();
    // window.open('/lobby')
    if (landingpageOnly) {
      this.props.history.push('/');
    } else if (this.props.user?.loginRedirect) {
      this.props.setLoginRedirect();
      const redirectUrl = `${this.props.user.loginRedirect.path}${
        this.props.user.loginRedirect.search
          ? `?${this.props.user.loginRedirect.search}`
          : ''
      }`;
      this.props.history.push(redirectUrl, this.props.user.loginRedirect.state);
    } else {
      this.props.history.push('/lobby');
    }
  };

  ssoCheckRegistrationStatus = async (email) => {
    let existingZohoRegistrant = false;

    const userInfo = await Auth.currentUserInfo();

    const existingRegistrant = await axios({
      method: 'GET',
      url: `/show-attendees/${eventId}/${email.toLowerCase()}`,
    });

    if (existingRegistrant.data.length) {
      if (
        existingRegistrant.data[0].email.toLowerCase() === email.toLowerCase()
      ) {
        existingZohoRegistrant = true;
      }
    } else {
      existingZohoRegistrant = false;
    }

    let registrationFields;

    if (!existingZohoRegistrant) {
      registrationFields = await axios({
        method: 'get',
        url: 'forms/' + eventId,
        // headers: { idtoken: data.idToken.jwtToken },
        params: { showId: eventId },
      })
        .then((response) => {
          let formObject = response.data.data;
          let registration = formObject.filter(
            (item) => item.type === 'registration'
          )[0];
          registration.fields = registration.fields
            .sort(function (a, b) {
              return a.field_id - b.field_id;
            })
            .filter((field) => field.is_enabled === true);
          let standardFields = registration.fields.filter(
            (field) => field.section === 'fieldsStandard'
          );
          let customFields = registration.fields.filter(
            (field) => field.section === 'fieldsCustom'
          );
          let questions = registration.fields.filter(
            (field) => field.section === 'question'
          );
          let requiredFields = registration.fields.filter(
            (field) => field.is_required === true
          );
          let multiSelectFields = registration.fields.filter(
            (field) => field.type === 'multi_select'
          );

          return [
            standardFields,
            customFields,
            questions,
            requiredFields,
            multiSelectFields,
          ];
        })
        .catch((error) => console.log(error));
    }

    // test assign site to ensure info pulls through
    // userInfo.attributes.address = 'West Point';
    // test end
    let potentialAddress = '';
    let potentialCity = '';
    let potentialState = '';
    let potentialZip = '';
    function assignData(merckSites, singleSite, source) {
      singleSite = merckSites.filter((site) => site.site === source);
      potentialAddress = `${singleSite[0]?.address_1}${
        singleSite[0]?.address_2 ? `, ${singleSite[0]?.address_2}` : ''
      }`;
      potentialCity = singleSite[0]?.city;
      potentialState = singleSite[0]?.state;
      potentialZip = singleSite[0]?.zip;
    }
    if (userInfo.attributes.address) {
      let singleSite;
      switch (userInfo.attributes.address) {
        case 'Boston':
          assignData(merckSites, singleSite, 'Boston');
          break;
        case 'Branchburg':
          assignData(merckSites, singleSite, 'Branchburg');
          break;
        case 'Cambridge':
          assignData(merckSites, singleSite, 'Cambridge');
          break;
        case 'Cherokee':
          assignData(merckSites, singleSite, 'Cherokee');
          break;
        case 'Durham':
          assignData(merckSites, singleSite, 'Durham');
          break;
        case 'Elkton':
          assignData(merckSites, singleSite, 'Elkton');
          break;
        case 'Kenilworth':
          assignData(merckSites, singleSite, 'Kenilworth');
          break;
        case 'Madison':
          assignData(merckSites, singleSite, 'Madison');
          break;
        case 'Palo Alto':
          assignData(merckSites, singleSite, 'Palo Alto');
          break;
        case 'Rahway':
          assignData(merckSites, singleSite, 'Rahway');
          break;
        case 'Singapore':
          assignData(merckSites, singleSite, 'Singapore');
          break;
        case 'South San Francisco':
          assignData(merckSites, singleSite, 'South San Francisco');
          break;
        case 'Upper Gwynedd':
          assignData(merckSites, singleSite, 'Upper Gwynedd');
          break;
        case 'West Point':
          assignData(merckSites, singleSite, 'West Point');
          break;
        case 'Whitehouse Station West':
          assignData(merckSites, singleSite, 'Whitehouse Station West');
          break;
        case 'Wilson':
          assignData(merckSites, singleSite, 'Wilson');
          break;
        default:
          break;
      }
    }

    // TODO possibly use those potential address info fields here
    this.setState({
      // user attributes
      firstName: userInfo.attributes.given_name,
      lastName: userInfo.attributes.family_name,
      country: userInfo.attributes.locale,
      // division: userInfo.attributes.zoneinfo,
      jobTitle: userInfo.attributes.profile,
      // site: userInfo.attributes.address,

      //form attributes
      formSubmitted: false,
      ssoEmailChecked: true,
      existingZohoRegistrant: existingZohoRegistrant,
      registrationFields: registrationFields,
      ssoSignedIn: true,
      email: email,
    });
  };

  render() {
    const { classes } = this.props;
    let sites = null;
    let siteAsDropdown = false;
    if (accountId === '1691992000015906075') {
      // event is Merck
      sites = merckSites;
      siteAsDropdown = true;
    }
    if (accountId === '1691992000015906109') {
      // event is Sanofi
      sites = sanofiSites;
      siteAsDropdown = true;
    }

    const createTextField = (field, index) => {
      let fieldName = fieldNameAlias[field.label];
      let fieldNameError = fieldNameAlias[field.label] + 'Error';
      let fieldNameErrorMessage = fieldNameAlias[field.label] + 'ErrorMessage';
      let shouldAutoFocus = field.label === 'First Name';
      let fieldLabel = field.label;
      if (field.name !== fieldNameAlias[field.label]) {
        fieldLabel = field.name;
      }

      return (
        <React.Fragment key={index}>
          <TextField
            required={field.is_required}
            onChange={(event) => this.handleChange(event, fieldName)}
            value={this.state[fieldName]}
            label={fieldLabel}
            error={this.state[fieldNameError]}
            helperText={this.state[fieldNameErrorMessage]}
            autoFocus={shouldAutoFocus}
            variant="filled"
          />
          <br />
        </React.Fragment>
      );
    };

    const createSelectField = (field, index) => {
      let fieldName = fieldNameAlias[field.label];

      let fieldNameError = fieldNameAlias[field.label] + 'Error';
      let fieldNameErrorMessage = fieldNameAlias[field.label] + 'ErrorMessage';
      let fieldChoices = field.choices;
      let fieldLabel = field.label;
      if (field.name !== fieldNameAlias[field.label]) {
        fieldLabel = field.name;
      }
      let isCountry = false;
      if (field.label === 'Country') {
        isCountry = true;
        fieldChoices = countryList;
      }
      let isSite = false;
      if (siteAsDropdown && field.label === 'Site') {
        isSite = true;
        fieldChoices = sites;
        fieldChoices = fieldChoices.map((choice) => {
          choice.value = choice.site;
          choice.label = choice.site;
          return choice;
        });
      }

      return (
        <React.Fragment key={index}>
          <FormControl className={classes.formControlDropdown}>
            <InputLabel
              id="demo-simple-select-label"
              error={this.state[fieldNameError]}
              style={{ paddingLeft: '12px' }}
            >
              {fieldLabel} {field.is_required && '*'}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="filled"
              value={this.state[fieldName]}
              error={this.state[fieldNameError]}
              onChange={(event) => {
                this.handleChoiceSelect(event, fieldName);
                isSite && this.populateAddressOnSiteSelect(event, sites);
              }}
              style={{ textAlign: 'left' }}
            >
              <MenuItem key={'blank' + index} value={''}>
                ---
              </MenuItem>
              {fieldChoices.map((choice, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={isCountry ? choice : choice.value}
                  >
                    {isCountry ? choice : choice.label}
                  </MenuItem>
                );
              })}
            </Select>
            {this.state[fieldNameErrorMessage] ? (
              <FormHelperText error={this.state[fieldNameError]}>
                {this.state[fieldNameErrorMessage]}
              </FormHelperText>
            ) : null}
            <br />
          </FormControl>
        </React.Fragment>
      );
    };

    const createMultiSelectField = (field, index) => {
      let fieldName = fieldNameAlias[field.label];
      let fieldNameError = fieldNameAlias[field.label] + 'Error';
      let fieldNameErrorMessage = fieldNameAlias[field.label] + 'ErrorMessage';
      let fieldLabel = field.label;
      if (field.name !== fieldNameAlias[field.label]) {
        fieldLabel = field.name;
      }
      // console.log(field, index);
      let requiredError;
      let potentialAnswers;
      if (field.is_required) {
        if (this.state[fieldNameError]) {
          requiredError = (
            <Typography color="error">
              {this.state[fieldNameErrorMessage]}
            </Typography>
          );
        }
      }
      let currentState = this.state[fieldName];

      potentialAnswers = field.choices.map((choice, i) => {
        let toggleState = `${fieldName}Option${i + 1}`;
        let textState = `${fieldName}Option${i + 1}Text`;
        return (
          <Grid key={i} item container xs={12}>
            <Grid item xs={1} className={classes.toggleButton}>
              <ToggleButton
                value="check"
                selected={this.state[toggleState]}
                onChange={() => {
                  if (this.state[toggleState]) {
                    currentState = currentState.replace(choice.value + ';', '');
                  } else {
                    currentState = currentState + choice.value + ';';
                  }
                  this.setState({
                    [toggleState]: !this.state[toggleState],
                    [textState]: choice.value,
                    [fieldName]: currentState,
                  });
                }}
                style={{ height: '50%', width: '50%' }}
              >
                <CheckIcon style={{ color: 'white' }} />
              </ToggleButton>
            </Grid>
            <Grid
              item
              xs={11}
              style={{ textAlign: 'initial', paddingLeft: '4px' }}
            >
              <Typography
                variant="body2"
                color="textPrimary"
                style={{ marginTop: '2px' }}
              >
                {choice.label}
              </Typography>
            </Grid>
          </Grid>
        );
      });
      return (
        <React.Fragment key={field.field_id + `_${index}`}>
          <br />
          <Typography variant="body1" color="textPrimary">
            {fieldLabel} {field.is_required && '*'}
          </Typography>
          {requiredError}
          <br />
          <Grid container justifyContent="flex-start">
            {potentialAnswers}
          </Grid>
          <Divider className={classes.divider} />
          <br />
        </React.Fragment>
      );
    };

    const standardFields = [];
    const customFields = [];
    const customQuestions = [];
    let attendanceQuestion = null;

    const sortAndAssignFields = (arrayName, field, index) => {
      if (field.type === 'text_field') {
        // likely also need to handle for case 'text_area' used in bio
        arrayName.push(createTextField(field, index));
      }
      if (field.type === 'select') {
        arrayName.push(createSelectField(field, index));
      }
      if (field.type === 'multi_select') {
        arrayName.push(createMultiSelectField(field, index));
      }
    };

    if (this.state.registrationFields) {
      this.state.registrationFields[0]?.forEach((field, index) => {
        if (field.label === 'Attendance') {
          attendanceQuestion = createMultiSelectField(field, index);
        } else if (field.label === 'Site' && siteAsDropdown) {
          standardFields.push(createSelectField(field, index));
        } else if (field.label === 'Job Title') {
          let fieldLabel = field.label;
          if (field.name !== fieldNameAlias[field.label]) {
            fieldLabel = field.name;
          }
          standardFields.push(
            <JobTitleLookupField
              key={123456789}
              value=""
              label={fieldLabel}
              onSelect={(value) => this.setState({ jobTitle: value })}
              error={this.state.jobTitleError}
              errorMessage={this.state.jobTitleErrorMessage}
              required={field.is_required}
            />
          );
        } else {
          sortAndAssignFields(standardFields, field, index);
        }
      });

      this.state.registrationFields[1]?.forEach((field, index) => {
        sortAndAssignFields(customFields, field, index);
      });

      this.state.registrationFields[2]?.forEach((field, index) => {
        sortAndAssignFields(customQuestions, field, index);
      });
    }

    let regForms = (
      <>
        {standardFields}
        {customFields}
        {customQuestions}
        <CompanyLookupField value="" onSelect={this.handleMultiSelect} />
        {attendanceQuestion}
      </>
    );

    let mainRegistrationForm = (
      <div>
        <NavBar />
        <Grid
          style={{ paddingTop: '85px' }}
          container
          layout={'row'}
          justifyContent="center"
          spacing={0}
        >
          <Grid item xs={12} sm={8} md={6}>
            <img
              src={
                this.props.event
                  ? this.props.event.data.banner_location
                  : landingPage.banner
              }
              alt="Lobby"
              className={classes.bannerImage}
            />
            <Paper className={classes.paper}>
              <br />
              <Typography variant="h5" style={{ color: 'black' }}>
                Alright! Let's get you set up.
              </Typography>
              <br />
              <Typography variant="body1" style={{ color: 'black' }}>
                Please fill out all fields below and confirm you have reviewed
                the Privacy Policy, then click Register.
              </Typography>
              <br />
              <FormControl
                className={classes.formControl}
                // removed because autocomplete component requires enter to submit entry
                // onKeyUp={(event) => {
                //   if (event.key === 'Enter') this.handleZohoRegistration();
                // }}
              >
                {regForms}
                <TextField
                  required
                  variant="filled"
                  onChange={(event) => this.handleChange(event, 'email')}
                  value={this.state.email}
                  label="Email"
                  disabled
                  error={this.state.emailError}
                  helperText={this.state.emailErrorMessage}
                />
              </FormControl>
              {/* <FormControl className={classes.formControl}>
                                <TextField
                                    type="password"
                                    onChange={(event) => this.handleChange(event, "password")}
                                    value={this.state.password}
                                    label="Password"
                                    error = {this.state.parameterError}
                                    helperText={this.state.parameterErrorMessage}
                                />
                            </FormControl> */}
              <FormControl style={{ margin: '24px 0px', width: '85%' }}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs={2} className={classes.toggleButton}>
                    <ToggleButton
                      value="check"
                      selected={this.state.privacySelected}
                      onChange={() => {
                        this.setState({
                          privacySelected: !this.state.privacySelected,
                        });
                      }}
                    >
                      <CheckIcon style={{ color: 'white' }} />
                    </ToggleButton>
                  </Grid>
                  <Grid item xs={6} style={{ padding: '0px 16px' }}>
                    <Typography variant="body2" color="textPrimary">
                      I have read and understand the Privacy Policy
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      onClick={() => this.handleModal(true, 'privacyOpen')}
                      className={classes.button}
                      style={{ height: '100%', width: '80%' }}
                    >
                      Privacy
                      <br />
                      Policy
                    </Button>
                  </Grid>
                </Grid>
              </FormControl>
              <FormControl className={classes.formControlButton}>
                <Button
                  variant="contained"
                  onClick={this.handleZohoRegistration}
                  className={classes.button}
                >
                  {this.state.formSubmitted ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    <div>Register</div>
                  )}
                </Button>
                <FormHelperText id="component-helper-text" error>
                  {this.state.privacyAgree
                    ? 'Please agree to the privacy policy to continue'
                    : ''}
                </FormHelperText>
                <FormHelperText id="component-helper-text" error>
                  {this.state.globalError ? 'Please rectify errors above' : ''}
                </FormHelperText>
              </FormControl>
              <DialogWrapper
                open={this.state.privacyOpen}
                handleClose={(e) => this.setState({ privacyOpen: false })}
              >
                <Privacy />
                <Button
                  className={classes.acceptButton}
                  variant="contained"
                  onClick={(e) =>
                    this.setState({
                      privacyOpen: false,
                      privacySelected: true,
                    })
                  }
                >
                  I Accept
                </Button>
                <Button
                  className={classes.closeButton}
                  variant="contained"
                  onClick={() => this.handleModal(false, 'privacyOpen')}
                >
                  Close
                </Button>
              </DialogWrapper>
            </Paper>
          </Grid>
        </Grid>
        <Button
          className={classes.privacyButton}
          variant="contained"
          onClick={() => this.handleModal(true, 'privacyOpen')}
        >
          View{' '}
          <Hidden smUp>
            <br />
          </Hidden>
          Privacy{' '}
          <Hidden smUp>
            <br />
          </Hidden>
          Policy
        </Button>
      </div>
    );

    if (this.state.ssoError) {
      return (
        <div>
          <NavBar />
          <div className={classes.grid}>
            <Grid
              className={classes.gridBody}
              container
              layout={'row'}
              justifyContent="center"
              spacing={0}
            >
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <img
                  src={
                    this.props.event
                      ? this.props.event.data.banner_location
                      : landingPage.banner
                  }
                  alt="Lobby"
                  className={classes.bannerImage}
                />
                <Paper className={classes.paper}>
                  <Typography variant="h5" style={{ color: 'black' }}>
                    There's been an error logging you in.
                  </Typography>
                  <Typography variant="body1" style={{ color: 'black' }}>
                    Please ensure you are on your company's VPN. If the problem
                    persists, please contact support.
                  </Typography>
                  <FormControl className={classes.formControlButton}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        this.setState({ ssoError: false });
                        this.props.history.push('/');
                      }}
                      className={classes.button}
                    >
                      Return to Home Page
                    </Button>
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    }

    if (this.state.ssoSignedIn) {
      if (this.state.existingZohoRegistrant) {
        return (
          <div>
            <NavBar />
            <div className={classes.grid}>
              <Grid
                className={classes.gridBody}
                container
                layout={'row'}
                justifyContent="center"
                spacing={0}
              >
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <img
                    src={
                      this.props.event
                        ? this.props.event.data.banner_location
                        : landingPage.banner
                    }
                    alt="Lobby"
                    className={classes.bannerImage}
                  />
                  <Paper className={classes.paper}>
                    <Typography variant="h5" style={{ color: 'black' }}>
                      You're Registered!
                    </Typography>
                    <FormControl className={classes.formControlButton}>
                      <Button
                        variant="contained"
                        onClick={() => this.ssoSignedInNavigation()}
                        className={classes.button}
                      >
                        {landingpageOnly
                          ? 'View Event Details'
                          : 'Enter Virtual Event'}
                      </Button>
                    </FormControl>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </div>
        );
      } else {
        return mainRegistrationForm;
      }
    }

    if (this.state.newPasswordRequired) {
      return (
        <div>
          <NavBar />
          <div className={classes.grid}>
            <Grid
              className={classes.gridBody}
              container
              layout={'row'}
              justifyContent="center"
              spacing={0}
            >
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <img
                  src={
                    this.props.event
                      ? this.props.event.data.banner_location
                      : landingPage.banner
                  }
                  alt="Lobby"
                  className={classes.bannerImage}
                />
                <Paper className={classes.paper}>
                  <Typography variant="h5" style={{ color: 'black' }}>
                    Create Permanent Password
                  </Typography>
                  <FormControl className={classes.formControl} fullWidth>
                    <TextField
                      variant="filled"
                      onChange={(event) => this.handleChange(event, 'email')}
                      value={this.state.email}
                      label="Email"
                      error={this.state.emailError}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl} fullWidth>
                    <TextField
                      variant="filled"
                      onChange={(event) =>
                        this.handleChange(event, 'newPassword')
                      }
                      type="password"
                      value={this.state.newPassword}
                      label="Password"
                      error={this.state.emailError}
                      helperText={this.state.emailErrorMessage}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlButton}>
                    <Button
                      variant="contained"
                      onClick={this.setPermanentPassword}
                      className={classes.button}
                    >
                      {this.state.formSubmitted ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        <div>Submit</div>
                      )}
                    </Button>
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    }

    if (this.state.emailChecked) {
      if (!this.state.existingZohoRegistrant) {
        return mainRegistrationForm;
      } else if (!this.state.existingCognitoUser) {
        return (
          <div>
            <NavBar />
            <Grid
              style={{ paddingTop: '85px' }}
              container
              layout={'row'}
              justifyContent="center"
              spacing={0}
            >
              <Grid item xs={12} sm={8} md={6}>
                <img
                  src={
                    this.props.event
                      ? this.props.event.data.banner_location
                      : landingPage.banner
                  }
                  alt="Lobby"
                  className={classes.bannerImage}
                />
                <Paper className={classes.paper}>
                  <br />
                  <Typography variant="h5" style={{ color: 'black' }}>
                    Perfect! Now let's make sure you can securely login
                  </Typography>
                  <br />
                  <Typography variant="body1" style={{ color: 'black' }}>
                    Please create a password using the form below
                  </Typography>
                  <br />
                  {/* <FormControl className={classes.formControl}>
                        <TextField
                            onChange={(event) => this.handleChange(event, "username")}
                            value={this.state.username}
                            label="Username"
                            fullWidth
                            error = {this.state.usernameError}
                            helperText={this.state.usernameError ? this.state.usernameErrorMessage : "Username cannot contain spaces"}
                        />
                    </FormControl> */}
                  <FormControl
                    className={classes.formControl}
                    onKeyUp={(event) => {
                      if (event.key === 'Enter') this.handleCognitoSignup();
                    }}
                  >
                    <TextField
                      variant="filled"
                      onChange={(event) => this.handleChange(event, 'email')}
                      value={this.state.email}
                      label="Email"
                      disabled
                      error={this.state.emailError}
                      helperText={this.state.emailErrorMessage}
                    />
                    <br />
                    <TextField
                      variant="filled"
                      type="password"
                      onChange={(event) => this.handleChange(event, 'password')}
                      value={this.state.password}
                      label="Password"
                      error={this.state.passwordError}
                      helperText={
                        this.state.passwordErrorMessage
                          ? this.state.passwordErrorMessage
                          : 'Password must be at least 8 characters long'
                      }
                      autoFocus
                    />
                  </FormControl>
                  <FormControl className={classes.formControlButton}>
                    <Button
                      variant="contained"
                      onClick={this.handleCognitoSignup}
                      className={classes.button}
                    >
                      {this.state.formSubmitted ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        <div>Next</div>
                      )}
                    </Button>
                  </FormControl>
                </Paper>
                <DialogWrapper
                  open={this.state.mustConfirmEmail}
                  handleClose={() =>
                    this.handleModal(false, 'mustConfirmEmail')
                  }
                >
                  <Grid
                    style={{ paddingTop: '60px' }}
                    container
                    layout={'row'}
                    justifyContent="center"
                    spacing={0}
                  >
                    <Grid item xs={12} sm={8} md={6}>
                      <Typography variant="h5" style={{ color: 'black' }}>
                        Please check your inbox to confirm your email
                      </Typography>
                      <Typography variant="body1" style={{ color: 'black' }}>
                        If you cannot find the confirmation email, wait a few
                        minutes and check your spam folder. If the problem
                        persists, please reach out for chat support in the
                        bottom right of your screen.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Button
                    className={classes.closeButton}
                    variant="contained"
                    onClick={() => this.handleModal(false, 'mustConfirmEmail')}
                  >
                    Next
                  </Button>
                </DialogWrapper>
              </Grid>
            </Grid>
          </div>
        );
      } else {
        return (
          <div>
            <NavBar />
            <div className={classes.grid}>
              <Grid
                className={classes.gridBody}
                container
                layout={'row'}
                justifyContent="center"
                spacing={0}
              >
                <Grid item xs={12} sm={8} md={6}>
                  <img
                    src={
                      this.props.event
                        ? this.props.event.data.banner_location
                        : landingPage.banner
                    }
                    alt="Lobby"
                    className={classes.bannerImage}
                  />
                  <Paper className={classes.paper}>
                    {this.state.justSignedUp ? (
                      <React.Fragment>
                        <br />
                        <Typography variant="h5" style={{ color: 'black' }}>
                          Beautiful! One last step.
                        </Typography>
                        <br />
                        <Typography variant="body1" style={{ color: 'black' }}>
                          Check your inbox for an email from
                          eregistration@planetconnect.com for your verification
                          link, then return here to sign in. The verification
                          link will only be valid for 1 hour.
                        </Typography>
                        <br />
                        {/* <FormControl className={classes.formControlButton}>
                                        <Button variant="contained" onClick={() => this.resendVerificationCode()} className={classes.button}>
                                            Resend Verification
                                        </Button>
                                    </FormControl> */}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <br />
                        <Typography variant="h5" style={{ color: 'black' }}>
                          Please input your password below to sign in.
                        </Typography>
                        <br />
                        <Typography variant="body1" style={{ color: 'black' }}>
                          If you have forgotten your password, you may reset it
                          below.
                        </Typography>
                        <br />
                      </React.Fragment>
                    )}

                    <FormControl
                      className={classes.formControl}
                      fullWidth
                      onKeyUp={(event) => {
                        if (event.key === 'Enter') this.signIn();
                      }}
                    >
                      <TextField
                        variant="filled"
                        onChange={(event) => this.handleChange(event, 'email')}
                        value={this.state.email}
                        label="Email"
                        error={this.state.emailError}
                        disabled
                      />
                      <br />
                      <TextField
                        variant="filled"
                        onChange={(event) =>
                          this.handleChange(event, 'password')
                        }
                        type="password"
                        value={this.state.password}
                        label="Password"
                        error={this.state.passwordError}
                        helperText={this.state.passwordErrorMessage}
                        autoFocus
                      />
                    </FormControl>
                    <FormControl className={classes.formControlButton}>
                      <Button
                        variant="contained"
                        onClick={this.signIn}
                        className={classes.button}
                      >
                        {this.state.formSubmitted ? (
                          <CircularProgress color="inherit" />
                        ) : (
                          <div>Enter</div>
                        )}
                      </Button>
                    </FormControl>
                    <FormControl className={classes.formControlButton}>
                      <Button
                        color="inherit"
                        component={Link}
                        to={'/forgotpassword'}
                      >
                        Reset Password
                      </Button>
                    </FormControl>
                  </Paper>
                </Grid>
              </Grid>
            </div>
            <DialogWrapper
              open={this.state.privacyOpen}
              handleClose={() => this.handleModal(false, 'privacyOpen')}
            >
              <Privacy />
              <Button
                className={classes.closeButton}
                variant="contained"
                onClick={() => this.handleModal(false, 'privacyOpen')}
              >
                Close
              </Button>
            </DialogWrapper>
            <Button
              className={classes.privacyButton}
              variant="contained"
              onClick={() => this.handleModal(true, 'privacyOpen')}
            >
              View{' '}
              <Hidden smUp>
                <br />
              </Hidden>
              Privacy{' '}
              <Hidden smUp>
                <br />
              </Hidden>
              Policy
            </Button>
          </div>
        );
      }
    }

    return (
      <div>
        <NavBar />
        <Grid
          style={{ paddingTop: '85px' }}
          container
          layout={'row'}
          justifyContent="center"
          spacing={0}
        >
          <Grid item xs={12} sm={8} md={6}>
            <img
              src={
                this.props.event
                  ? this.props.event.data.banner_location
                  : landingPage.banner
              }
              alt="Lobby"
              className={classes.bannerImage}
            />
            <Paper className={classes.paper}>
              <br />
              <Typography variant="h5" style={{ color: 'black' }}>
                Welcome to the {this.props.event.data.title}!
              </Typography>
              <br />
              <Typography variant="body1" style={{ color: 'black' }}>
                Let's get started! Please provide your email address below and
                click next.
                <br />
                We'll determine if you've registered already.
              </Typography>
              {/* <Typography variant="body1">
                                Please click the button below to test PlanetConnect and Merck's SSO connection
                            </Typography> */}
              <br />
              <FormControl
                className={classes.formControl}
                onKeyUp={(event) => {
                  if (event.key === 'Enter') this.checkEmail();
                }}
              >
                <TextField
                  required
                  variant="filled"
                  onChange={(event) => this.handleChange(event, 'email')}
                  value={this.state.email}
                  label="Email"
                  error={this.state.emailError}
                  helperText={this.state.emailErrorMessage}
                  autoFocus
                />
              </FormControl>
              <FormControl className={classes.formControlButton}>
                <br />
                <Button
                  type="submit"
                  variant="contained"
                  onClick={this.checkEmail}
                  className={classes.button}
                >
                  {this.state.formSubmitted ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    <div>Next</div>
                  )}
                </Button>
                <br />
                {OktaSSOEnabled ? (
                  <>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={() => Auth.federatedSignIn({ provider: 'Okta' })}
                      className={classes.button}
                    >
                      Login with Okta
                    </Button>
                    <br />
                  </>
                ) : null}
                {/* {MerckSSOEnabled ? (
                  <>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={() =>
                        Auth.federatedSignIn({ provider: 'Merck' })
                      }
                      className={classes.button}
                    >
                      Login with Merck SSO
                    </Button>
                    <br />
                  </>
                ) : null} */}
              </FormControl>
              <DialogWrapper
                open={this.state.privacyOpen}
                handleClose={(e) => this.setState({ privacyOpen: false })}
              >
                <Privacy />
                <Button
                  className={classes.acceptButton}
                  variant="contained"
                  onClick={(e) =>
                    this.setState({ privacyOpen: false, privacySelected: true })
                  }
                >
                  I Accept
                </Button>
                <Button
                  className={classes.closeButton}
                  variant="contained"
                  onClick={() => this.handleModal(false, 'privacyOpen')}
                >
                  Close
                </Button>
              </DialogWrapper>
            </Paper>
          </Grid>
        </Grid>
        <Button
          className={classes.privacyButton}
          variant="contained"
          onClick={() => this.handleModal(true, 'privacyOpen')}
        >
          View{' '}
          <Hidden smUp>
            <br />
          </Hidden>
          Privacy{' '}
          <Hidden smUp>
            <br />
          </Hidden>
          Policy
        </Button>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setAuthenticatedUser: setAuthenticatedUser,
      setLoginRedirect: setLoginRedirect,
      setBriefcase: setBriefcase,
      setEventDetails: setEventDetails,
      trackAction: trackAction,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.queryApi.queries?.[`getEvent("${eventId}")`],
    // event: queryApi.endpoints.getEvent.select(eventId)(state),
    briefcase: state.queryApi.queries?.[`getBriefcase("${eventId}")`],
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(SignUpForm)));
