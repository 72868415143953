import React, { Component, Suspense } from 'react';
// Helmet
import { Helmet } from 'react-helmet';
import { title, faviconLocation, MerckSSOEnabled } from './config';

// Matierial Theme
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import { colors, demoEnabled, landingpageOnly, eventId } from './config';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setStrapiElements from './Store/Actions/setStrapiElements';
import Layout from './Components/MiniSidebar';
import ProtectedRoute from './Components/ProtectedRoute';
import NotificationWatcher from './Components/NotificationWatcher';

// Configure Router
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Lobby from './Containers/Lobby';
import LandingPageDemo from './Containers/LandingPages/LandingPageDemo';
import EngagementZone from './Containers/EngagementZone';
import ChatSidebar from './Containers/ChatSidebar';
import Signup from './Containers/Authentication/Signup';
import Signin from './Containers/Authentication/Signin';
import ForgotPassword from './Containers/Authentication/ForgotPassword';
import ExhibitHall from './Containers/Exhibits/ExhibitionHall';
import ExhibitionBooth from './Containers/Exhibits/ExhibitBooth';
import DiscussionForum from './Containers/DiscussionForum';
import PosterSessions from './Containers/Posters/PosterSessions';
import Poster from './Containers/Posters/PosterFull';
import Art from './Containers/Art/Art';
import Help from './Containers/Help';
import Agenda from './Containers/Agenda/Agenda';
import MyPersonalAgenda from './Containers/Agenda/MyPersonalAgenda';
import Briefcase from './Containers/Briefcase/Briefcase';
import ConferenceCenter from './Containers/ConferenceCenter/ConferenceCenter';
import ChatRoom from './Containers/Chat/Chat';
import Unregistered from './Containers/Unregistered';
import Settings from './Containers/Settings';
import Leads from './Containers/Leads/Leads';
import Reporting from './Containers/Reporting/Layout';
import DigitalLab from './Containers/Exhibits/DigitalLab';
import WhatsNew from './Containers/WhatsNew';
import Debug from './Containers/Debug';
import Registration from './Containers/Authentication/Registration';
import RegistrationBeforeSSO from './Containers/Authentication/RegistrationBeforeSSO';
import SSO from './Containers/Authentication/SSO';
import UnderConstruction from './Containers/UnderConstruction';
import AbstractSubmission from './Containers/Authentication/AbstractSubmission';
import AccountSettings from './Containers/AttendeeAccount/AccountSettings';
import Workshop from './Containers/Workshop/Workshop';
import QRScanScreen from './Containers/QRScanScreen';
import ActivitiesHall from './Containers/Activities/ActivitiesHall';
import LandingPage from './Containers/LandingPage';
import ActionScreen from './Containers/ActionScreen';
import PollResultsScreen from './Containers/PollResultsScreen';
import LeaderboardScreen from './Containers/LeaderboardScreen';
import VendorQuery from './Containers/VendorQueries/ChallengeDashboard';
import DemoLandingPage from './Containers/LandingPages/LandingPageDemo.jsx';
import LeadRetrieval from './Containers/LeadRetrieval.jsx';

import axios from 'axios';

// import ScienceAsArt from './Containers/ScienceAsArt/ScienceAsArtHall';
// import Archives from './Containers/Archive';

import { queryApi } from './Services/queryApi';

class Router extends Component {
  constructor(props) {
    super(props);
    this.state = {
      strapiPageRoutes: null,
      abstractAvailable: false,
    };
  }

  components = {
    Lobby,
    LandingPageDemo,
    Signup,
    Signin,
    ForgotPassword,
    ExhibitHall,
    DigitalLab,
    Debug,
    ExhibitionBooth,
    DiscussionForum,
    PosterSessions,
    Poster,
    Art,
    WhatsNew,
    Help,
    Agenda,
    Briefcase,
    MyPersonalAgenda,
    ConferenceCenter,
    ChatRoom,
    Unregistered,
    Layout,
    Settings,
    Leads,
    Reporting,
    // Privacy,
    AbstractSubmission,
    AccountSettings,
    Workshop,
    ActivitiesHall,
    EngagementZone,
    QRScanScreen,
    VendorQuery,
  };

  loadingGraphic = (
    <Grid container align="center">
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <br />
        <br />
        <CircularProgress color="inherit" />
        <Typography variant="h4" color="textPrimary">
          Loading...
        </Typography>
      </Grid>
    </Grid>
  );

  async componentDidMount() {
    await this.props.setStrapiElements();
    if (this.props.strapi?.strapi) {
      await this.setStrapiPages(this.props.strapi.strapi);
    }
    await this.getAbstractSubmission();
  }

  async setStrapiPages(strapiPages) {
    let strapiPageRoutes = [];
    strapiPages.menu_items.forEach((item) => {
      if (item.pages.length === 1) {
        // single item
        strapiPageRoutes.push(
          <Route
            exact
            key={`strapi_page_${item.id}`}
            path={`/${item.pages[0].slug}`}
            render={(props) => <LandingPage />}
          />
        );
      } else {
        // multiple items
        item.pages.forEach((page) => {
          strapiPageRoutes.push(
            <Route
              exact
              key={`strapi_page_${page.slug}`}
              path={`/${page.slug}`}
              render={(props) => <LandingPage />}
            />
          );
        });
      }
    });
    this.setState({ strapiPageRoutes });
  }

  async getAbstractSubmission() {
    let registrationFields = await axios({
      method: 'get',
      url: 'forms/' + eventId,
      // headers: { idtoken: data.idToken.jwtToken },
      params: { showId: eventId },
    });
    registrationFields = registrationFields.data.data;
    if (registrationFields[1].is_enabled) {
      this.setState({ abstractAvailable: true });
    }
  }

  render() {
    const event = this.props.event?.data;
    const theme = createTheme({
      palette: {
        primary: {
          main: event?.main_color ? event.main_color : colors.main, //"#311B4C",
          light: event?.light_color ? event.light_color : colors.light, //'#a380cf'
        },
        secondary: {
          main: '#2b377c',
        },
      },
    });

    const { strapiPageRoutes } = this.state;

    let zohoSalesIqScript;
    let googleTagManager;
    let googleDataLayer;
    let indexing;

    let homePage;

    if (demoEnabled) {
      googleTagManager = (
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-T4TE73PM0Z"
        ></script>
      );

      googleDataLayer = (
        <script>
          {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-T4TE73PM0Z');`}
        </script>
      );

      zohoSalesIqScript = (
        <script type="text/javascript" id="zsiqchat">
          {`var $zoho = $zoho || {};
        $zoho.salesiq = $zoho.salesiq || {
          widgetcode:
            'b6446ccf314360784ae16351be4c27a129f7ffe0de9c7bb07624be95d894c87eeb2a5c143039246ce0c80c95a0280405',
          values: {},
          ready: function () {},
        };
        var d = document;
        s = d.createElement('script');
        s.type = 'text/javascript';
        s.id = 'zsiqscript';
        s.defer = true;
        s.src = 'https://salesiq.zoho.com/widget';
        t = d.getElementsByTagName('script')[0];
        t.parentNode.insertBefore(s, t);`}
        </script>
      );

      homePage = (
        <Route
          exact
          key="/landingpagedemo"
          path="/"
          render={(props) => <LandingPageDemo />}
        />
      );
    } else {
      zohoSalesIqScript = (
        <script type="text/javascript" id="zsiqchat">
          {`var $zoho = $zoho || {};
        $zoho.salesiq = $zoho.salesiq || {
          widgetcode:
            'bae35401627d95c796b4ab826839f7550e10cab10d485812886004438114089b201c52485bd3462a4490fe4c8e27d674679df280cb279534ae1b68109fdda64d',
          values: {},
          ready: function () {},
        };
        var d = document;
        s = d.createElement('script');
        s.type = 'text/javascript';
        s.id = 'zsiqscript';
        s.defer = true;
        s.src = 'https://salesiq.zoho.com/widget';
        t = d.getElementsByTagName('script')[0];
        t.parentNode.insertBefore(s, t);`}
        </script>
      );

      indexing = <meta name="robots" content="noindex,nofollow" />;

      homePage = (
        <Route
          key="/landingpage"
          path="/"
          render={(props) => <LandingPage />}
        />
      );
    }

    let defaultHelmet = (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{event?.title || title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Virtual Environment Powered by PlanetConnect"
        />
        <link
          rel="icon"
          type="image/png"
          href={this.props.event ? event.favicon_location : faviconLocation}
          sizes="16x16"
        />
        <link
          rel="apple-touch-icon"
          href={this.props.event ? event.favicon_location : faviconLocation}
        />

        {zohoSalesIqScript}
        {googleTagManager}
        {googleDataLayer}
        {indexing}
      </Helmet>
    );

    const standardLoginRoutes = [
      <Route
        key="/signin"
        path="/signin"
        render={(props) => <Registration />}
      />,
      <Route
        key="/forgotpassword"
        path="/forgotpassword"
        render={(props) => <ForgotPassword />}
      />,
      strapiPageRoutes,
      <Route
        key="/registration"
        exact
        path="/registration"
        render={(props) => <Registration />}
      />,
      <Route
        key="/authentication"
        exact
        path="/authentication"
        render={(props) => <SSO />}
      />,
      // <Route key="/scan" path="/scan" render={(props) => <QRScanScreen />} />,
    ];

    const ssoLoginRoutesLandingPageOnly = [
      <Route
        key="/signin"
        path="/signin"
        render={(props) => <Registration />}
      />,
      <Route
        key="/forgotpassword"
        path="/forgotpassword"
        render={(props) => <ForgotPassword />}
      />,
      strapiPageRoutes,
      // sso step 1, go through sso
      <Route
        key="/authenticationSSO"
        exact
        path="/authentication"
        render={(props) => <SSO />}
      />,
      // step 2, come back from sso, grab data,
      // prevent user from registering as show attendee
      <Route
        exact
        key="/registrationBeforeSSO"
        path="/registration"
        render={(props) => <RegistrationBeforeSSO />}
      />,
      // <Route key="/scan" path="/scan" render={(props) => <QRScanScreen />} />,
      <Route
        key="/action/:action"
        path="/action/:action"
        render={(props) => <ActionScreen />}
      />,
      <Route
        key="/polls/:pollId"
        path="/polls/:pollId"
        render={(props) => <PollResultsScreen />}
      />,
      <Route
        key="/leaderboard"
        path="/leaderboard"
        render={(props) => <LeaderboardScreen />}
      />,
    ];

    let abstractSubmission = null;
    if (this.state?.abstractAvailable) {
      abstractSubmission = (
        <Route
          key="/abstractsubmission"
          path="/abstractsubmission"
          render={(props) => <AbstractSubmission />}
        />
      );
    }

    // Available Attendee Routes
    if (landingpageOnly) {
      if (!this.props.user.user) {
        return (
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              {defaultHelmet}
              <Switch>
                {MerckSSOEnabled
                  ? ssoLoginRoutesLandingPageOnly
                  : standardLoginRoutes}
                <Route path="/" render={(props) => <LandingPage />} />
              </Switch>
            </BrowserRouter>
          </ThemeProvider>
        );
      } else {
        return (
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              {defaultHelmet}
              <Switch>
                {abstractSubmission}
                {standardLoginRoutes}
                <Route path="/" render={(props) => <LandingPage />} />
              </Switch>
            </BrowserRouter>
          </ThemeProvider>
        );
      }
    }
    if (this.props.user?.user === 'unregistered') {
      return (
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            {defaultHelmet}
            <Switch>
              {abstractSubmission}
              {standardLoginRoutes}
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      );
    }

    if (
      this.props.user?.user?.roles &&
      event?.under_construction &&
      !this.props.user?.user?.email
        ?.toLowerCase()
        ?.includes('@planetconnect.com')
    ) {
      return (
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            {defaultHelmet}
            <Layout>
              <Switch>
                {abstractSubmission}
                <Route path="/event" render={(props) => <LandingPage />} />
                <Route path="/" render={(props) => <UnderConstruction />} />
              </Switch>
            </Layout>
          </BrowserRouter>
        </ThemeProvider>
      );
    }
    if (event?.available_routes) {
      return (
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            {defaultHelmet}
            <Layout>
              <Switch>
                {standardLoginRoutes}

                <ProtectedRoute
                  path="/lobby"
                  render={(props) => (
                    <Lobby timestamp={new Date().toString()} {...props} />
                  )}
                />
                <ProtectedRoute
                  path="/exhibithall"
                  render={(props) => (
                    <ExhibitHall timestamp={new Date().toString()} {...props} />
                  )}
                />
                <ProtectedRoute
                  path="/debug101"
                  render={(props) => (
                    <Debug timestamp={new Date().toString()} {...props} />
                  )}
                />
                <ProtectedRoute
                  path="/discussionforum"
                  render={(props) => (
                    <DiscussionForum
                      timestamp={new Date().toString()}
                      {...props}
                    />
                  )}
                />
                <ProtectedRoute
                  path="/posterhall"
                  render={(props) => (
                    <PosterSessions
                      timestamp={new Date().toString()}
                      {...props}
                    />
                  )}
                />
                <ProtectedRoute
                  path="/help"
                  render={(props) => (
                    <Help timestamp={new Date().toString()} {...props} />
                  )}
                />
                <ProtectedRoute
                  path="/agenda"
                  render={(props) => (
                    <Agenda timestamp={new Date().toString()} {...props} />
                  )}
                />
                <ProtectedRoute
                  path="/briefcase"
                  render={(props) => (
                    <Briefcase timestamp={new Date().toString()} {...props} />
                  )}
                />
                <ProtectedRoute
                  path="/mypersonalagenda"
                  render={(props) => (
                    <MyPersonalAgenda
                      timestamp={new Date().toString()}
                      {...props}
                    />
                  )}
                />
                <ProtectedRoute
                  path="/auditorium"
                  render={(props) => (
                    <ConferenceCenter
                      timestamp={new Date().toString()}
                      {...props}
                    />
                  )}
                />
                <ProtectedRoute
                  path="/settings"
                  render={(props) => (
                    <Settings timestamp={new Date().toString()} {...props} />
                  )}
                />
                <ProtectedRoute
                  path="/leads"
                  render={(props) => (
                    <Leads timestamp={new Date().toString()} {...props} />
                  )}
                />
                <ProtectedRoute
                  path="/reporting"
                  render={(props) => (
                    <Reporting timestamp={new Date().toString()} {...props} />
                  )}
                />
                <ProtectedRoute
                  path="/accountsettings"
                  render={(props) => (
                    <AccountSettings
                      timestamp={new Date().toString()}
                      {...props}
                    />
                  )}
                />
                <ProtectedRoute
                  path="/workshop"
                  render={(props) => (
                    <Workshop timestamp={new Date().toString()} {...props} />
                  )}
                />
                <ProtectedRoute
                  path="/activitieshall"
                  render={(props) => (
                    <ActivitiesHall
                      timestamp={new Date().toString()}
                      {...props}
                    />
                  )}
                />
                <ProtectedRoute
                  path="/engagementzone"
                  render={(props) => (
                    <EngagementZone
                      timestamp={new Date().toString()}
                      {...props}
                    />
                  )}
                />
                <ProtectedRoute
                  path="/scan"
                  render={(props) => (
                    <QRScanScreen
                      timestamp={new Date().toString()}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/action/:action"
                  render={(props) => <ActionScreen />}
                />
                <Route
                  key="/polls/:pollId"
                  path="/polls/:pollId"
                  render={(props) => <PollResultsScreen />}
                />
                <Route
                  key="/leaderboard"
                  path="/leaderboard"
                  render={(props) => <LeaderboardScreen />}
                />
                <Route
                  key="/leadretrieval"
                  path="/leadretrieval"
                  render={(props) => <LeadRetrieval />}
                />
                {abstractSubmission}
                <Route path="/event" render={(props) => <LandingPage />} />
                <Route
                  exact
                  path="/"
                  render={(props) =>
                    this.props.user?.user ? (
                      <Lobby />
                    ) : demoEnabled ? (
                      <DemoLandingPage />
                    ) : (
                      <LandingPage />
                    )
                  }
                />
              </Switch>
              {this.props.user && this.props.twilioInitialized && (
                <ChatSidebar />
              )}
              {this.props.user && this.props.twilioInitialized && (
                <NotificationWatcher />
              )}
            </Layout>
          </BrowserRouter>
        </ThemeProvider>
      );
    }

    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          {defaultHelmet}
          <Switch>{standardLoginRoutes}</Switch>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.queryApi.queries?.[`getEvent("${eventId}")`],
    strapi: state.strapi,
    twilioInitialized:
      state.twilio.conversationsInitialized && state.twilio.hereNowInitialized,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setStrapiElements: setStrapiElements,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Router);
