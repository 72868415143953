import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Auth } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '../config';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import BusinessIcon from '@material-ui/icons/Business';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocalCafeIcon from '@material-ui/icons/LocalCafe';
import getIncludedText from '../Utilities/getIncludedText';
import trackAction from '../Store/Actions/tracker';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const useStyles = (theme) => ({
  searchBox: {
    // width: '20vw',
    backgroundColor: 'white',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    '& .MuiFormLabel-root': {
      fontSize: '0.85rem',
      paddingLeft: theme.spacing(1),
    },
    searchWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
});

class GlobalSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: '',
      searchError: false,
      searchErrorMessage: '',
      searchResults: {
        options: [],
        getOptionLabel: (option) => option.title,
      },
    };
  }

  handleSearchTypeAhead = async (text) => {
    try {
      this.setState({
        searchQuery: text,
      });

      if (text.length >= 0) {
        const currentUser = await Auth.currentSession();

        const initialSearchResults = await axios({
          method: 'get',
          url: `search/${this.props.eventId}/opensearch?q=${this.state.searchQuery}`,
          headers: { idtoken: currentUser.idToken.jwtToken },
        });

        let options = [];

        let max;
        if (initialSearchResults.data.hits.hits.length < 25) {
          max = initialSearchResults.data?.hits?.hits?.length;
        } else {
          max = 25;
        }

        for (let i = 0; i < max; i++) {
          let result = initialSearchResults.data.hits.hits[i];

          if (result._source.abstract_id) {
            if (result._source.accepted_type === 'Poster') {
              result.type = 'Poster';
            } else if (result._source.accepted_type === 'Talk') {
              result.type = 'Talk';
            } else if (result._source.accepted_type === 'Workshop') {
              result.type = 'Workshop';
            } else if (result._source.accepted_type === 'Track Talk') {
              result.type = 'Track Talk';
            } else if (result._source.accepted_type === 'Product Theater') {
              result.type = 'Product Theater';
            }
            // may need another type to send to networking page
            // if (result._source.types === 'Talk') {
            //   result.type = 'Talk';
            // } else {
            //   result.type = 'Poster';
            // }
          } else if (result._source.exhibition_id) {
            result.type = 'Exhibit';
          } else {
            result.type = 'Other';
          }

          const includedText = getIncludedText(
            result._source,
            this.state.searchQuery.toLowerCase()
          );
          if (includedText) {
            result.includedText = includedText;
            // options.push(result);
          }

          options.push(result);
        }
        options = options.sort((a, b) => {
          if (a.type < b.type) {
            return -1;
          } else {
            return 1;
          }
        });

        this.setState({
          searchResults: {
            options: options,
            getOptionLabel: (option) => option.title,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  selectGlobalSearch(value) {
    let urlVisited;
    if (value.value) {
      switch (value.value.type) {
        case 'Networking':
          this.props.history.push(`/discussionforum/`);
          //window.location.reload();
          urlVisited = '/discussionforum';
          break;
        case 'Poster':
          this.props.history.push(`/posterhall/?poster_id=${value.value._id}`);
          //window.location.reload();
          urlVisited = '/posterhall';
          break;
        case 'External':
          this.props.history.push(
            `/exhibithall/?exhibition_id=${value.value._id}`
          );
          //window.location.reload();
          urlVisited = '/exhibithall';
          break;
        case 'Internal':
          this.props.history.push(
            `/exhibithall/?exhibition_id=${value.value._id}`
          );
          //window.location.reload();
          urlVisited = '/exhibithall';
          break;
        case 'Exhibit':
          this.props.history.push(
            `/exhibithall/?exhibition_id=${value.value._id}`
          );
          //window.location.reload();
          urlVisited = 'exhibithall';
          break;
        case 'Talk':
          this.props.history.push(
            `/agenda/?presentation_id=${value.value._id}`
          );
          //window.location.reload();
          urlVisited = '/agenda';
          break;
        case 'Workshop':
          this.props.history.push(`/workshop/?workshop_id=${value.value._id}`);
          //window.location.reload();
          urlVisited = '/workshop';
          break;
        case 'Product Theater':
        case 'Track Talk':
          this.props.history.push(
            `/agenda/?presentation_id=${value.value._id}`
          );
          //window.location.reload();
          urlVisited = '/agenda';
          break;
        case 'Plenary Poster':
          this.props.history.push(`/posterhall/?poster_id=${value.value._id}`);
          //window.location.reload();
          urlVisited = '/posterhall';
          break;
        default:
          this.props.history.push('/');
      }
    }

    const event = {
      action: 'Searched',
      resource_type: 'GlobalSearch',
      resource_id: value.value._id,
      url_visited: urlVisited,
      resource_account_id: null,
      resource_account_name: null,
      resource_name: null,
    };

    this.props.trackAction(event);
  }

  render() {
    const { classes } = this.props;

    return (
      <Autocomplete
        filterOptions={(x) => x}
        groupBy={(option) => option.type}
        style={{
          width: this.props.isMobile && '100%',
        }}
        // getOptionLabel={(option) => option.title}
        {...this.state.searchResults}
        renderOption={(props, option) => {
          let icon = null;

          if (props.type === 'Poster') {
            icon = <FilterNoneIcon style={{ paddingRight: 5 }} />;
          } else if (props.type === 'Exhibit') {
            icon = <BusinessIcon style={{ paddingRight: 5 }} />;
          } else if (
            props.type === 'Talk' ||
            props.type === 'Track Talk' ||
            props.type === 'Product Theater'
          ) {
            icon = <AccessTimeIcon style={{ paddingRight: 5 }} />;
          }
          return (
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
              }}
            >
              {icon}
              <Box>
                {props._source.title || props._source.account_name}
                {props.includedText && (
                  <Box
                    sx={{
                      borderTop: `1px solid ${colors.disabledGray}`,
                      marginTop: 10,
                      overflow: 'hidden',
                      width: '100%',
                    }}
                  >
                    <Typography
                      noWrap
                      style={{
                        maxHeight: 45,
                        width: 250,
                        lineClamp: 2,
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {props.includedText.firstHalf}
                      <b>{this.state.searchQuery}</b>
                      {props.includedText.secondHalf}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          );
        }}
        onChange={(event, value) => this.selectGlobalSearch({ value })}
        onOpen={() => {
          this.setState({
            searchQuery: '',
            searchResults: {
              options: [],
              getOptionLabel: (option) => option.title,
            },
          });
        }}
        renderInput={(params) => (
          <TextField
            className={classes.searchBox}
            style={{ width: this.props.isMobile ? '100%' : '20vw' }}
            onChange={(event) => this.handleSearchTypeAhead(event.target.value)}
            {...params}
            label="Global Search"
            fullWidth
          />
        )}
      />
    );
  }
}

// export default withRouter(withStyles(useStyles)(GlobalSearch));

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      trackAction: trackAction,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(withStyles(useStyles)(withRouter(GlobalSearch)));
