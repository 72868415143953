import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link, withRouter, useLocation } from 'react-router-dom';
import moment from 'moment';
import PubNubReact from 'pubnub';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import PersonIcon from '@material-ui/icons/Person';

import FormControl from '@material-ui/core/FormControl';

// table components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import getChatMessages from '../Store/Actions/getChatMessages';
import addChatSubscription from '../Store/Actions/addChatSubscription';
import setChatMembership from '../Store/Actions/setChatMembership';
import selectActivePrivateChat from '../Store/Actions/selectActivePrivateChat';
import setChatbar from '../Store/Actions/setChatbar';
import viewPrivateChat from '../Store/Actions/viewPrivateChat';

const useStyles = (theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      marginTop: theme.spacing(4),
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
});

class LeadRetrival extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lead: null,
    };
  }

  componentDidMount() {
    // http://localhost:3000/leadretrieval?email=aapicello@planetconnect.com

    let lead;

    if (this.props.location.search) {
      let query = new URLSearchParams(this.props.location.search);
      for (let param of query.entries()) {
        if (param[0] === 'email') {
          lead = param[1];
        }
      }
    }

    this.props.user.user;

    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'post',
          url: `/audit/leads/${this.props.event.event.show_id}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            lead: lead,
            attendee_id: this.props.user.user.attendee_id,
            account_id: this.props.user.user.account_id,
            resource_account_id: this.props.user.user.account_id,
            resource_account_name: this.props.user.user.account_name,
          },
        }).then((response) => {
          this.setState({ lead: response.data[0] });
        });
      })
      .catch((error) => {
        console.log(error);
        alert(
          `Error: Could not collect lead. Please inform event management. Error: ${error}`
        );
      });
  }

  render() {
    const { classes } = this.props;

    let lead = this.state.lead;
    console.log(this.state.lead);

    return (
      <React.Fragment>
        <br />
        <Paper>
          <Typography
            variant="h4"
            gutterBottom
            align="center"
            style={{ padding: '12px' }}
          >
            Lead Retrieval
          </Typography>
        </Paper>
        {this.state.lead ? (
          <Accordion expanded className={classes.root}>
            <AccordionSummary
              className={classes.expansionPanelMain}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography variant="h6" component="div">
                {lead.first_name + ' ' + lead.last_name} has been added to your
                Leads!
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" align="left">
                    {lead.email ? (
                      <React.Fragment>
                        <b>Email:</b> {lead.email}
                      </React.Fragment>
                    ) : (
                      <React.Fragment />
                    )}
                  </Typography>
                  <Typography variant="body1" align="left">
                    {lead.account_name ? (
                      <React.Fragment>
                        <b>Company:</b> {lead.account_name}
                      </React.Fragment>
                    ) : (
                      <React.Fragment />
                    )}
                  </Typography>
                  <Typography variant="body1" align="left">
                    {lead.title ? (
                      <React.Fragment>
                        <b>Title:</b> {lead.title}
                      </React.Fragment>
                    ) : (
                      <React.Fragment />
                    )}
                  </Typography>
                  <Typography variant="body1" align="left">
                    {lead.site ? (
                      <React.Fragment>
                        <b>Site:</b> {lead.site}
                      </React.Fragment>
                    ) : (
                      <React.Fragment />
                    )}
                  </Typography>
                  <Typography variant="body1" align="left">
                    {lead.department ? (
                      <React.Fragment>
                        <b>Department:</b> {lead.department}
                      </React.Fragment>
                    ) : (
                      <React.Fragment />
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
            <Button
              variant="contained"
              size="large"
              className={classes.downloadButton}
              component={Link}
              to="/leads"
            >
              <PersonIcon style={{ marginRight: '8px' }} />
              See All Leads
            </Button>
          </Accordion>
        ) : null}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
  };
}

export default connect(
  mapStateToProps,
  null
)(withRouter(withStyles(useStyles)(LeadRetrival)));
