import { setConversation, setOpenConversation } from '../Reducers/twilio';
import actionScanPoster from './actionScanPoster';
import actionScanPoll from './actionScanPoll';
import actionScanAttendee from './actionScanAttendee';
import trackAction from './tracker';

function isNumeric(num) {
  return !isNaN(num);
}

export default function routeQRCode(history, decodedText) {
  return async (dispatch, getState) => {

    console.log('decoded text: ', decodedText)

    if(decodedText.length > 12){
      return dispatch(actionScanPoster(history, decodedText))
      }


    // Check for Attendee Badge Number
    if (isNumeric(decodedText)) {
      // throw new Error('attendee badge scan does route does not exist');
      return dispatch(actionScanAttendee(decodedText));
    }

    const decodedUrl = new URL(decodedText);

    // TODO: Ensure this domain is this event
    // for now this allows us to use localhost
    // if (decodedUrl.host !== EVENT_URL) throw new Error('invalid URL')

    // example /action/poster
    const pathName = decodedUrl.pathname;
    const urlParams = new URLSearchParams(decodedUrl.search);
    // TODO: REMOVE
    if (decodedText.toLowerCase() === 'https://www.google.com') {
      return 'You scanned the Google URL!';
    }
    if (decodedText.toLowerCase().split('//')[1] === 'www.google.com') {
      return 'You scanned the Google URL!';
    }

    // TODO: add track action
    const me = getState()?.user?.user;

    const event = {
      action: 'Scanned',
      resource_type: 'QR',
      resource_id: pathName,
      url_visited: '/scan',
      resource_account_id: me?.account_id,
      resource_account_name: me?.account_name,
      resource_name: me?.email,
    };
    dispatch(trackAction(event));

    if (pathName === '/action/scan_poster') {
      // for ?poster_id=1234, posterId will be "1234"
      const posterId = urlParams.get('poster_id');
      return dispatch(actionScanPoster(history, posterId));
    }
    if (pathName === '/action/vote_poll') {
      // for ?poll_id=1234, posterId will be "1234"
      // for ?answer=1, answer will be "1"
      const pollId = urlParams.get('poll_id');
      const answer = urlParams.get('answer');

      return dispatch(actionScanPoll(pollId, answer));
    }
    if (pathName === '/action/scan_attendee') {
      const badgeKey = urlParams.get('badge_key');

      return dispatch(actionScanAttendee(badgeKey));
    }
  };
}
