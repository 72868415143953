import { queryApi } from '../Services/queryApi';
import { eventId, CLEAR_CACHE_AFTER } from '../config';
import {
  getClearCacheAfter,
  getUser,
  setAuthenticatedUserState,
  setClearCacheAfter,
} from '../Store/Reducers/user';
import Event from '../Types/Event';
import moment from 'moment';
import logoutUser from '../Store/Actions/logoutUser';
import axios from 'axios';
import { Auth } from 'aws-amplify';

export default function startupAsync(user) {
  async function getShowAttendee(loadedUser, eventId, email) {
    loadedUser = await axios.get(`show-attendees/${eventId}/${email}`);
    return loadedUser;
  }

  return async (dispatch, getState) => {
    console.log('startup async');
    // const user = getUser(getState());
    async function getCurrentAuthenticatedUser() {
      console.log('getCurrentAuthenticatedUser start');
      try {
        await Auth.currentSession();
        await Auth.currentAuthenticatedUser();
        console.log('getCurrentAuthenticatedUser success');
      } catch {
        console.log('getCurrentAuthenticatedUser failed');

        await dispatch(logoutUser());
      }
    }
    // let token;
    // try {
    //   await Auth.currentSession();
    //   token = true;
    // } catch (error) {
    //   token = false;
    // }

    // console.log(token);
    const clearCacheAfter = getClearCacheAfter(getState());

    if (!clearCacheAfter || CLEAR_CACHE_AFTER > clearCacheAfter) {
      console.log(
        `forcing a cache clear for ${moment(CLEAR_CACHE_AFTER).format(
          'YYYY-MM-DD HH:mm:ss'
        )}`
      );
      await dispatch(
        queryApi.util.invalidateTags('Event', 'Briefcase', 'Exhibit', 'Poster')
      );
      dispatch(setClearCacheAfter(CLEAR_CACHE_AFTER));
    }

    if (user) {
      console.log('user exist');
      // testing to re assign session token 4/14/2023
      // await Auth.currentAuthenticatedUser();
      getCurrentAuthenticatedUser();
      console.log('token refreshed');
      // end test
      let loadedUser;
      try {
        // validate current token is valid

        loadedUser = await getShowAttendee(loadedUser, eventId, user.email);
        if (!loadedUser?.data?.[0]?.attendee_id) {
          // try again because database was empty
          // retry due to race condition with zoho workflow updating db
          loadedUser = await getShowAttendee(loadedUser, eventId, user.email);
        }
      } catch (e: any) {
        console.log('🚀 ~ AXIOS ERROR', e.message);
        // Network Error means user is offline
        if (e.message === 'Network Error') {
          loadedUser = { data: 'Network Error' };
        }
      }

      // if we didn't get an the user and we didn't get a no network error, log the user out
      // console.log(
      //   '🚀 ~ file: StartupAsync.ts ~ line 64 ~ return ~ loadedUser',
      //   loadedUser
      // );

      if (
        // Is the user is not offline and the user cannot be loaded
        loadedUser?.data !== 'Network Error' &&
        !loadedUser?.data?.[0]?.attendee_id
      ) {
        console.log(loadedUser?.data, loadedUser?.data?.[0]?.attendee_id);
        console.log(
          'Could not load user, logging out. Log out skipped for MTS.'
        );

        // dispatch(logoutUser());
        return;
      } else if (loadedUser?.data?.[0]?.attendee_id) {
        // success, continue
        // console.log('success continue');
        // dispatch(setAuthenticatedUserState(loadedUser.data));
      }

      try {
        dispatch(
          queryApi.endpoints.getAgendas.initiate({
            eventId: eventId,
          })
        );

        dispatch(queryApi.endpoints.getPosters.initiate(eventId));

        dispatch(queryApi.endpoints.getExhibits.initiate(eventId));

        dispatch(queryApi.endpoints.getBriefcase.initiate(eventId));

        dispatch(queryApi.endpoints.getNetworking.initiate(eventId));
      } catch (e) {
        console.log('🚀 ~ file: StartupAsync.js ~ line 24 ~ return ~ e', e);
      }
    }
    // let token;
    // try {
    //   await Auth.currentSession();
    //   token = true;
    // } catch (error) {
    //   token = false;
    // }
    // if (!token) {
    //   console.log('user session expired, logging out');
    //   await dispatch(logoutUser());
    // }
  };
}
