import { eventId } from '../../config';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { queryApi } from '../../Services/queryApi';

const getLeadsArray = (user) => {
  return async (dispatch, getState) => {
    // const leadsArray = await dispatch(
    //   queryApi.endpoints.getLeads.initiate(eventId)
    // );

    let user = await Auth.currentSession();

    let leadsArray = await axios({
      method: 'GET',
      url: `/audit/${eventId}`,
      headers: {
        idtoken: user.idToken.jwtToken,
      },
      params: {
        order_by: 'visit_time',
        order_dir: 'asc',
      },
    });

    if (!leadsArray?.data?.data) {
      return {
        leadsOnlyArray: [],
        leadsArray: [],
        leadsReporting: [],
        leadsArrayObjects: [],
      };
    }

    let filteredData = [];

    if (user.exhibitor_lead_retrieval === 'View Own') {
      for (let i = 0; i < leadsArray.data.data.length; i++) {
        if (leadsArray.data.data[i].resource_type === 'Exhibit') {
          filteredData.push(leadsArray.data.data[i]);
        } else {
          // CONDITIONAL CHECK if no contacts somehow... set equal to empty array
          if (!leadsArray.data.data[i]?.contacts?.length) {
            leadsArray.data.data[i].contacts = [];
          }

          for (let j = 0; j < leadsArray.data.data[i].contacts.length; j++) {
            if (leadsArray.data.data[i].contacts[j].email === user.email) {
              filteredData.push(leadsArray.data.data[i]);
            }
          }
        }
      }
    } else {
      filteredData = leadsArray.data.data;
    }

    let arrayCSV = filteredData.map((event) => ({
      'Account Owner': event.resource_owner,
      Action: event.action,
      Type: event.resource_type,
      'Resource Name': event.resource_name,
      'User Account': event.visitor_account_name,
      Email: event.visitor_email,
      'First Name': event.visitor_first_name,
      'Last Name': event.visitor_last_name,
      'Job Title': event.visitor_title,
      Department: event.visitor_department,
      Site: event.visitor_site,
      'Address 1': event.visitor_street,
      'Address 2': null,
      City: event.visitor_city,
      'Zip Code': '=""' + event.visitor_zip + '""',
      State: event.visitor_state,
      Country: event.visitor_country,
      'Time Visited': event.visit_time,
      Notes: event.note,
      Tags: event.tags,
    }));

    let users = [];

    filteredData.map((event) => {
      let presence = users
        .map((user) => user.email)
        .indexOf(event.visitor_email);

      if (presence === -1) {
        if (event.resource_name === 'Exhibitor Lead Retrieval') {
          let eventPresence;
          let noteId;

          if (event.note || event.tags) {
            eventPresence = event.note;
            noteId = event.note_id;
          }

          users.push({
            firstName: event.visitor_first_name,
            lastName: event.visitor_last_name,
            email: event.visitor_email,
            company: event.visitor_account_name,
            title: event.visitor_title,
            site: event.visitor_site,
            department: event.visitor_department,
            inPersonLead: true,
            note: eventPresence,
            noteId: noteId,
            tags: event.tags,
            address: event.visitor_street,
            city: event.visitor_city,
            zipCode: '=""' + event.visitor_zip + '""',
            actions: [
              {
                action: event.action,
                visited: event.url_visited,
                type: event.resource_type,
                name: event.resource_name,
                id: event.resource_id,
                createdAt: event.visit_time,
                auditId: event.audit_id,
              },
            ],
          });
        } else {
          let eventPresence;
          let noteId;
          if (event.note || event.tags) {
            eventPresence = event.note;
            noteId = event.note_id;
          }

          users.push({
            firstName: event.visitor_first_name,
            lastName: event.visitor_last_name,
            email: event.visitor_email,
            company: event.visitor_account_name,
            title: event.visitor_title,
            site: event.visitor_site,
            department: event.visitor_department,
            inPersonLead: false,
            note: eventPresence,
            noteId: noteId,
            tags: event.tags,
            address: event.visitor_street,
            city: event.visitor_city,
            zipCode: '=""' + event.visitor_zip + '""',
            actions: [
              {
                action: event.action,
                visited: event.url_visited,
                type: event.resource_type,
                name: event.resource_name,
                id: event.resource_id,
                createdAt: event.visit_time,
                auditId: event.audit_id,
                note: event.note,
              },
            ],
          });
        }
      } else {
        if (event.note || event.tags) {
          users[presence].note = event.note;
          users[presence].noteId = event.note_id;
          users[presence].tags = event.tags;
        }

        if (event.resource_name === 'Exhibitor Lead Retrieval') {
          users[presence].inPersonLead = true;
        }

        users[presence].actions.push({
          action: event.action,
          visited: event.url_visited,
          type: event.resource_type,
          name: event.resource_name,
          id: event.resource_id,
          createdAt: event.visit_time,
          auditId: event.audit_id,
        });
      }

      return null;
    });

    let leadsOnlyArrayCSV = users.map((user) => ({
      'First Name': user.firstName,
      'Last Name': user.lastName,
      Email: user.email,
      Title: user.title,
      Company: user.company,
      Department: user.department,
      'In Person Lead': user.inPersonLead,
      'Lead Site': user.site,
      Address: user.address,
      City: user.city,
      'Zip Code': user.zipCode,
      Note: user.note,
      Tags: user.tags,
    }));

    console.log(users);
    console.log(filteredData);

    return {
      leadsOnlyArray: leadsOnlyArrayCSV,
      leadsArray: arrayCSV,
      leadsReporting: filteredData,
      leadsArrayObjects: users,
    };
  };
};

export default getLeadsArray;
