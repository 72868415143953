import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';

//Other
import { useSelector } from 'react-redux';
import setSidebar from '../Store/Actions/setSidebar';
import setChatbar from '../Store/Actions/setChatbar';
import logoutUser from '../Store/Actions/logoutUser';
import Snackbar from './Snackbar';
import PrivateChat from '../Containers/Chat/PrivateChat';
import ActivePrivateChat from '../Containers/Chat/ActivePrivateChat';
import GlobalSearch from './GlobalSearch';
import { viewPrivateFileNewWindow } from '../Utilities/viewPrivateFile';

// Icons
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CropFreeIcon from '@material-ui/icons/CropFree';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import BusinessIcon from '@material-ui/icons/Business';
import HelpIcon from '@material-ui/icons/Help';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import PanoramaIcon from '@material-ui/icons/Panorama';
import ForumIcon from '@material-ui/icons/Forum';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import WorkIcon from '@material-ui/icons/Work';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import SettingsIcon from '@material-ui/icons/Settings';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import MyleadsIcon from '@material-ui/icons/HowToReg';
import LocalCafeIcon from '@material-ui/icons/LocalCafe';
import BarChartIcon from '@material-ui/icons/BarChart';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import PersonIcon from '@material-ui/icons/Person';
import CastForEducationIcon from '@material-ui/icons/CastForEducation';
import WidgetsIcon from '@material-ui/icons/Widgets';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import TableChartIcon from '@material-ui/icons/TableChart';

import { drawerWidth, rightDrawerWidth, eventId } from '../config';
import isOnline from '../Utilities/isOnline';
import { useGetEventQuery } from '../Services/queryApi';
import { getUser } from '../Store/Reducers/user';
import { useAppDispatch } from '../Store/store';
import { getSidebar, getChatbar } from '../Store/Reducers/sidebar';
import arrayComparison from '../Utilities/arrayComparison';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#ebebeb',
    minHeight: '100vh',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.primary.main,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down('xs')]: {
      width: `calc(108% - ${drawerWidth}px)`,
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarShiftRight: {
    marginRight: rightDrawerWidth,
    width: `calc(100% - ${rightDrawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarShiftBoth: {
    marginRight: rightDrawerWidth,
    width: `calc(100% - ${drawerWidth + rightDrawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(1),
    width: 48,
    height: 48,
  },
  rightMenuButton: {
    marginLeft: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  rightDrawer: {
    width: rightDrawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  rightDrawerOpen: {
    width: rightDrawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  rightDrawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(0) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(0) + 1,
    },
    // width: '0px',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: theme.spacing(5),
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(9),
      marginTop: theme.spacing(5),
    },
  },
  title: {
    flexGrow: 11,
    textAlign: 'center',
  },
  agendaPdf: {
    flexGrow: 1,
    textAlign: 'center',
  },
  listPadding: {
    marginBottom: theme.spacing(7),
  },
}));

const MiniDrawer = (props) => {
  const { width } = props;
  const isMobile = width !== 'xs';

  const dispatch = useAppDispatch();
  let history = useHistory();

  const user = useSelector(getUser);
  const sidebarOpen = useSelector(getSidebar);
  const chatbarOpen = useSelector(getChatbar);
  const { data: event, isLoading, error } = useGetEventQuery(eventId);

  const classes = useStyles();
  const online = useSelector(isOnline);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [allowedRoutes, setAllowedRoutes] = useState([]);
  const [globalSearchOpen, setGlobalSearchOpen] = useState(false);

  const components = {
    IconButton,
    MenuIcon,
    OndemandVideoIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    BusinessIcon,
    HelpIcon,
    ExitToAppIcon,
    HomeIcon,
    FilterNoneIcon,
    PanoramaIcon,
    ForumIcon,
    CropFreeIcon,
    AccessTimeIcon,
    WorkIcon,
    FeaturedPlayListIcon,
    BubbleChartIcon,
    SettingsIcon,
    GpsFixedIcon,
    MyleadsIcon,
    LocalCafeIcon,
    BarChartIcon,
    FiberNewIcon,
    EventAvailableIcon,
    PersonIcon,
    CastForEducationIcon,
    WidgetsIcon,
    TableChartIcon,
  };

  useEffect(() => {
    if (!user) {
      setAllowedRoutes([]);
    } else {
      const userRoles = user?.roles.split(', ');
      const filteredRoutes = JSON.parse(event.available_routes).routes.filter(
        (route) => arrayComparison(userRoles, route.allowedRoles)
      );
      setAllowedRoutes(filteredRoutes);
    }
  }, [user, event]);

  const signOut = async () => {
    await dispatch(logoutUser());
    history.push('/');
  };

  const turnOffGlobalSearch = () => {
    setGlobalSearchOpen(false);
  };

  let navigationIcons;
  let dividerIndex;
  if (
    !event.under_construction ||
    (event.under_construction &&
      user?.email?.toLowerCase()?.includes('@planetconnect.com'))
  ) {
    navigationIcons = allowedRoutes.map((navigation: any, index) => {
      const TagName = components[navigation.icon];

      if (navigation.title === 'Divider') {
        dividerIndex = index;
        return <Divider key={`divider${index}`} />;
      }
      if (navigation.title === 'Debug') {
        return null;
      }
      let navTitle = navigation.title;
      let route = navigation.route;
      if (
        (navTitle === 'Account Settings' || navTitle === 'Engagement Zone') &&
        !online
      ) {
        navTitle = 'User is Offline. Cannot perform action.';
        route = null;
      }

      return (
        <React.Fragment key={navigation.title}>
          <Tooltip title={navTitle} placement="right">
            <ListItem
              button
              component={Link}
              to={route}
              onClick={() => {
                if (navTitle !== 'Account Settings' || online) {
                  if (window.innerWidth < 600) {
                    dispatch(setSidebar(false));
                  }
                }
              }}
            >
              <ListItemIcon>
                <TagName />
              </ListItemIcon>
              <ListItemText primary={navigation.title} />
            </ListItem>
          </Tooltip>
        </React.Fragment>
      );
    });

    // DEFAULT TEMPLATE FOR MANUALLY ADDING A NEW AVAILABLE ROUTE
    // // add to navigation icons
    // navigationIcons.push(
    //   <React.Fragment key={'leaderboard'}>
    //     <Tooltip title="Leaderboard" placement="right">
    //       <ListItem
    //         button
    //         key={'Leaderboard'}
    //         component={Link}
    //         to={'/leaderboard'}
    //       >
    //         <ListItemIcon>
    //           <TableChartIcon />
    //         </ListItemIcon>
    //         <ListItemText primary={'Leaderboard'} />
    //       </ListItem>
    //     </Tooltip>
    //   </React.Fragment>
    // );
    // // move last icon in list directly underneath divider
    // navigationIcons.splice(dividerIndex + 1, 0, navigationIcons.pop());
  }

  let agendaPdf;

  if (
    user?.roles?.includes('Pharma Attendee') ||
    user?.roles?.includes('Attendee')
  ) {
    agendaPdf = event.agenda_private_location;
  } else {
    agendaPdf = event.agenda_public_location;
  }

  // user is not logged in
  if (!user) return props.children;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: sidebarOpen,
          [classes.appBarShiftRight]: chatbarOpen,
          [classes.appBarShiftBoth]: chatbarOpen && sidebarOpen,
        })}
      >
        {!globalSearchOpen ? (
          <Toolbar style={{ paddingRight: '0px', width: '100%' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => dispatch(setSidebar(true))}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: sidebarOpen,
              })}
            >
              <MenuIcon />
            </IconButton>
            {event.icon_location && isMobile ? (
              <Button component={Link} to="/">
                <img
                  src={event.icon_location}
                  alt="Event Logo"
                  style={{ height: '60px' }}
                />
              </Button>
            ) : null}
            <Button
              className={classes.agendaPdf}
              onClick={() => viewPrivateFileNewWindow(agendaPdf)}
            >
              <Typography
                variant="button"
                style={{ textDecoration: 'none', color: 'white' }}
              >
                AGENDA
                <br />
                PDF
              </Typography>
            </Button>
            {isMobile && (
              <>
                <Button
                  component={Link}
                  to={'/event'}
                  className={classes.agendaPdf}
                >
                  <Typography
                    variant="button"
                    style={{ textDecoration: 'none', color: 'white' }}
                  >
                    Resources
                  </Typography>
                </Button>
                <Typography
                  variant="body1"
                  className={classes.title}
                  align="left"
                >
                  Welcome, {user.first_name} <br />
                  from {user.account_name}!
                </Typography>
                <GlobalSearch eventId={event.show_id} />
              </>
            )}
            {!isMobile && (
              <IconButton
                color="inherit"
                aria-label="open globalSearch"
                onClick={() => setGlobalSearchOpen(true)}
                style={{
                  width: 48,
                  height: 48,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <SearchIcon
                  style={{
                    width: 30,
                    height: 30,
                  }}
                />
              </IconButton>
            )}
          </Toolbar>
        ) : (
          <Toolbar
            style={{
              paddingRight: '0px',
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <GlobalSearch eventId={event.show_id} isMobile={true} />
            <IconButton
              color="inherit"
              aria-label="open globalSearch"
              onClick={turnOffGlobalSearch}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        )}
      </AppBar>
      {isMobile ? (
        <Drawer
          variant="permanent"
          anchor="left"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: sidebarOpen,
            [classes.drawerClose]: !sidebarOpen,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: sidebarOpen,
              [classes.drawerClose]: !sidebarOpen,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={() => dispatch(setSidebar(false))}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List className={classes.listPadding}>
            {navigationIcons}
            {user?.roles?.includes('Booth Admin') && (
              <a
                rel="noopener noreferrer"
                href="https://admin.planetconnect.com/"
                target="_blank"
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <Tooltip title="To Admin Portal" placement="right">
                  <ListItem button key={'To Admin Portal'}>
                    <ListItemIcon>
                      <SupervisorAccountIcon />
                    </ListItemIcon>
                    <ListItemText primary={'To Admin Portal'} />
                  </ListItem>
                </Tooltip>
              </a>
            )}
            <Tooltip title="Sign Out" placement="right">
              <ListItem
                onClick={signOut}
                button
                key={'Sign Out'}
                component={Link}
                to={'/'}
              >
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary={'Sign Out'} />
              </ListItem>
            </Tooltip>
          </List>
        </Drawer>
      ) : (
        <Drawer
          anchor="left"
          open={!!sidebarOpen}
          onClose={() => dispatch(setSidebar(false))}
        >
          <List className={classes.listPadding}>
            {navigationIcons}
            <Tooltip title="Resources" placement="right">
              <ListItem
                button
                key={'Resources'}
                component={Link}
                to={'/event'}
                onClick={() => dispatch(setSidebar(false))}
              >
                <ListItemIcon>
                  <AllInboxIcon />
                </ListItemIcon>
                <ListItemText primary={'Resources'} />
              </ListItem>
            </Tooltip>
            {user?.roles?.includes('Booth Admin') ? (
              <Tooltip title="To Admin Portal" placement="right">
                <a
                  rel="noopener noreferrer"
                  href="https://admin.planetconnect.com/"
                  target="_blank"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <ListItem button key={'To Admin Portal'}>
                    <ListItemIcon>
                      <SupervisorAccountIcon />
                    </ListItemIcon>
                    <ListItemText primary={'To Admin Portal'} />
                  </ListItem>
                </a>
              </Tooltip>
            ) : null}
            <Tooltip title="Sign Out" placement="right">
              <ListItem
                onClick={signOut}
                button
                key={'Sign Out'}
                component={Link}
                to={'/'}
              >
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary={'Sign Out'} />
              </ListItem>
            </Tooltip>
          </List>
        </Drawer>
      )}

      <main className={classes.content} style={{ overflow: 'auto' }}>
        {props.children}
        <Snackbar />
      </main>
      <Drawer
        id="chat"
        variant="persistent"
        anchor="right"
        className={clsx(classes.rightDrawer, {
          [classes.rightDrawerOpen]: chatbarOpen,
          [classes.rightDrawerClose]: !chatbarOpen,
        })}
        classes={{
          paper: clsx({
            [classes.rightDrawerOpen]: chatbarOpen,
            [classes.rightDrawerClose]: !chatbarOpen,
          }),
        }}
        open={!!chatbarOpen}
      >
        <div
          className={classes.toolbar}
          style={{
            position: 'fixed',
            backgroundColor: 'white',
            zIndex: '1000',
            width: rightDrawerWidth,
          }}
        >
          <ActivePrivateChat />
          <IconButton onClick={() => dispatch(setChatbar(false))}>
            <ChevronRightIcon />
          </IconButton>
          <Divider />
        </div>
        <PrivateChat />
      </Drawer>
    </div>
  );
};

export default withWidth()(MiniDrawer);
