import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useLocation, useHistory } from 'react-router-dom';
import Lobby from '../Containers/Lobby';

import { eventId } from '../config';
import { getUser, setLoginRedirect } from '../Store/Reducers/user';
import { useGetEventQuery } from '../Services/queryApi';
import arrayComparison from '../Utilities/arrayComparison';
// Redux

const ProtectedRoute = ({ path, exact, render }) => {
  const dispatch = useDispatch();

  const { data: event, error, isLoading } = useGetEventQuery(eventId);

  const user = useSelector(getUser);
  const location = useLocation();
  const history = useHistory();
  const thisRoute =
    event?.available_routes &&
    JSON.parse(event?.available_routes)?.routes?.find(
      (route) => route.route === path && route.title !== 'Divider'
    );

  useEffect(() => {
    if (!isLoading && event && !user) {
      dispatch(setLoginRedirect({ ...location }));
      // console.log('not logged in redirecting to /registration');
      history.push('/registration');
    }
  }, [user, isLoading, event, location]);

  // Route not loaded from event
  if (!thisRoute) return null;

  // useEffect will ask the user to login
  if (!user) return null;

  // Check Roles
  const myRoles = user?.roles?.split(',')?.map((role) => role.trim()) || [];
  // console.log(
  //   '🚀 ~ file: ProtectedRoute.tsx ~ line 35 ~ ProtectedRoute ~ thisRoute',
  //   thisRoute.allowedRoles
  // );
  // console.log(
  //   '🚀 ~ file: ProtectedRoute.tsx ~ line 57 ~ ProtectedRoute ~ myRoles',
  //   myRoles
  // );
  let hasRole = arrayComparison(myRoles, thisRoute.allowedRoles);

  // if (!hasRole) return <Lobby />;

  if (!hasRole) {
    history.push('/lobby');
  }

  return <Route path={path} exact={exact} render={render} />;
};

export default ProtectedRoute;
