import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
// import PubNubReact from 'pubnub';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';

import FormControl from '@material-ui/core/FormControl';
import GetAppIcon from '@material-ui/icons/GetApp';
import CircularProgress from '@material-ui/core/CircularProgress';

// table components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import IndividualLead from './IndividualLead';
import { eventId } from '../../config';

// reporting components
import Reporting from './Reporting.Leads';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import getChatMessages from '../../Store/Actions/getChatMessages';
import addChatSubscription from '../../Store/Actions/addChatSubscription';
import setChatMembership from '../../Store/Actions/setChatMembership';
import selectActivePrivateChat from '../../Store/Actions/selectActivePrivateChat';
import setChatbar from '../../Store/Actions/setChatbar';
import viewPrivateChat from '../../Store/Actions/viewPrivateChat';
import trackAction from '../../Store/Actions/tracker';
import { setChatWindow } from '../../Store/Reducers/twilio';
import twilioCreateJoinOneOnOneConversation from '../../Store/Actions/twilioCreateJoinOneOnOneConversation';
import getLeadsArray from '../../Store/Actions/getLeadsArray';
// import { queryApi } from '../../Services/queryApi';
import Chip from '@material-ui/core/Chip';

// Utils
import getTwilioOneOnOneConversation from '../../Utilities/getTwilioOneOnOneConversation';

// Download to CSV
import { CSVLink } from 'react-csv';

const useStyles = (theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      marginTop: theme.spacing(4),
    },
    '& .MuiChip-root	': {
      alignSelf: 'center',
    },
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  downloadLink: {
    textDecoration: 'none',
  },
  downloadButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '100%',
    padding: theme.spacing(1),
    margin: 'auto',
    '& .MuiButton-textSizeLarge': {
      size: 'large',
    },
  },
  downloadButtonContainer: {
    paddingLeft: '16px',
    // intentionally using 'sm' below
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
      paddingTop: theme.spacing(2),
    },
  },
  chatButtonContainer: {
    textAlign: 'end',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
});

class Leads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      note: this.props.lead.note,
      existingNote:
        this.props.lead.note || this.props.lead.tags?.length ? true : false,
      submitted: false,
      validationError: false,
      errorText: false,
      tags: this.props.lead.tags ? this.props.lead.tags.split(',') : [],
      newTag: '',
      tagSubmitted: false,
      tagErrorText: null,
      noteId: this.props.lead.noteId ? this.props.lead.noteId : null,
    };
  }

  noteTextUpdate(event) {
    this.setState({
      note: event.target.value,
    });
  }

  tagTextUpdate(event) {
    this.setState({
      newTag: event.target.value,
    });
  }

  componentDidMount() {
    if (this.state.existingNote) {
      Auth.currentSession()
        .then((data) => {
          axios({
            method: 'GET',
            url: `/notes/${this.state.noteId}`,
            headers: {
              idtoken: data.idToken.jwtToken,
            },
          }).then((response) => {
            this.setState({
              note: response.data[0].note,
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  postNote() {
    if (!this.state.note) {
      this.setState({
        submitted: false,
        errorText: 'Error: Must Enter Text',
      });
    } else if (this.state.existingNote) {
      Auth.currentSession()
        .then((data) => {
          axios({
            method: 'PUT',
            url: `/notes/${this.state.noteId}`,
            headers: {
              idtoken: data.idToken.jwtToken,
            },
            data: {
              note: this.state.note,
              // auditId: this.props.lead.actions[0].auditId,
              // attendee_id: this.props.user.user.attendee_id,
            },
          }).then(
            (response) => {
              this.setState({
                submitted: true,
                errorText: null,
              });
            },
            (error) => {
              this.setState({
                submitted: false,
                errorText: 'Error: Update Failed',
              });
            }
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      Auth.currentSession()
        .then((data) => {
          axios({
            method: 'POST',
            url: `/notes`,
            headers: {
              idtoken: data.idToken.jwtToken,
            },
            data: {
              note: this.state.note,
              auditId: this.props.lead.actions[0].auditId,
              // attendee_id: this.props.user.user.attendee_id,
            },
          }).then(
            (response) => {
              this.setState({
                submitted: true,
                existingNote: true,
                errorText: null,
                noteId: response.data[0].note_id,
              });
            },
            (error) => {
              this.setState({
                submitted: false,
                errorText: 'Error: Unable to Create Note',
              });
            }
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  handleTagDelete(i) {
    if (this.state.tags.length == 1) {
      this.setState({
        tagSubmitted: false,
        tagErrorText: 'Error: Must Have at Least One Tag',
      });
    } else {
      let newTagArray = this.state.tags;

      newTagArray.splice(i, 1);

      Auth.currentSession()
        .then((data) => {
          axios({
            method: 'PUT',
            url: `/notes/${this.state.noteId}`,
            headers: {
              idtoken: data.idToken.jwtToken,
            },
            data: {
              tags: newTagArray,
            },
          }).then(
            (response) => {
              this.setState({
                tagSubmitted: true,
                tags: newTagArray,
                tagErrorText: null,
              });
            },
            (error) => {
              this.setState({
                tagSubmitted: false,
                tagErrorText: 'Error: Update Failed',
              });
            }
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  handleTagAdd(text) {
    if (!this.state.newTag) {
      this.setState({
        tagSubmitted: false,
        tagErrorText: 'Error: Must Enter Text',
      });
    } else {
      let newTagArray = this.state.tags;

      newTagArray.push(this.state.newTag);

      if (this.state.existingNote) {
        Auth.currentSession()
          .then((data) => {
            axios({
              method: 'PUT',
              url: `/notes/${this.state.noteId}`,
              headers: {
                idtoken: data.idToken.jwtToken,
              },
              data: {
                tags: newTagArray,
                // note: this.state.note,
                // auditId: this.props.lead.actions[0].auditId,
                // attendee_id: this.props.user.user.attendee_id,
              },
            }).then(
              (response) => {
                this.setState({
                  tagSubmitted: true,
                  tags: newTagArray,
                  newTag: '',
                  tagErrorText: null,
                });
              },
              (error) => {
                this.setState({
                  tagSubmitted: false,
                  tagErrorText: 'Error: Update Failed',
                });
              }
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        Auth.currentSession()
          .then((data) => {
            axios({
              method: 'POST',
              url: `/notes`,
              headers: {
                idtoken: data.idToken.jwtToken,
              },
              data: {
                tags: newTagArray,
                // note: this.state.note,
                auditId: this.props.lead.actions[0].auditId,
                // attendee_id: this.props.user.user.attendee_id,
              },
            }).then(
              (response) => {
                this.setState({
                  tags: newTagArray,
                  newTag: '',
                  tagSubmitted: true,
                  tagErrorText: null,
                  existingNote: true,
                  noteId: response.data[0].note_id,
                });
              },
              (error) => {
                this.setState({
                  tagSubmitted: false,
                  errorText: 'Error: Unable to Create Tag',
                });
              }
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }

  render() {
    const { classes } = this.props;

    const lead = this.props.lead;

    let submitButton;

    let renderTags = this.state.tags.map((tag, i) => {
      return (
        <Chip
          color={'primary'}
          label={tag.replace('{', '').replace('}', '').replace('/', '')}
          onDelete={() => this.handleTagDelete(i)}
        />
      );
    });

    if (this.state.submitted) {
      submitButton = (
        <Button
          color={'primary'}
          className={classes.buttonLearn}
          variant="contained"
          size="medium"
          onClick={() => this.postNote()}
        >
          Note Successfully Saved!
        </Button>
      );
    } else if (this.state.errorText) {
      submitButton = (
        <Button
          error
          className={classes.buttonLearn}
          variant="contained"
          size="medium"
          onClick={() => this.postNote()}
        >
          {this.state.errorText}
        </Button>
      );
    } else {
      submitButton = (
        <Button
          className={classes.buttonLearn}
          variant="contained"
          size="medium"
          onClick={() => this.postNote()}
        >
          Save Note
        </Button>
      );
    }

    let tagSubmitButton;

    if (this.state.tagSubmitted) {
      tagSubmitButton = (
        <Button
          color={'primary'}
          className={classes.buttonLearn}
          variant="contained"
          size="medium"
          onClick={() => this.handleTagAdd()}
        >
          Success! Add Another
        </Button>
      );
    } else if (this.state.tagErrorText) {
      tagSubmitButton = (
        <Button
          error
          className={classes.buttonLearn}
          variant="contained"
          size="medium"
          onClick={() => this.handleTagAdd()}
        >
          {this.state.tagErrorText}
        </Button>
      );
    } else {
      tagSubmitButton = (
        <Button
          className={classes.buttonLearn}
          variant="contained"
          size="medium"
          onClick={() => this.handleTagAdd()}
        >
          Save Tag
        </Button>
      );
    }

    return (
      <Accordion
        className={classes.root}
        defaultExpanded={this.props.defaultExpanded}
      >
        <AccordionSummary
          className={classes.expansionPanelMain}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="h6" component="div">
            {lead.firstName + ' ' + lead.lastName}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            direction="row"
            spacing={2}
            alignItems="top"
            justifyContent="space-between"
          >
            <Grid item sm={12} md={4}>
              <Typography
                variant="h6"
                gutterBottom
                align="center"
                style={{ padding: '12px' }}
              >
                Contact Information
              </Typography>
              <InputLabel id="demo-multiple-chip-label">
                All Available Data Regarding Your Lead
              </InputLabel>
              <br />
              <Typography variant="body1" align="left">
                {lead.email ? (
                  <React.Fragment>
                    <b>Email:</b> {lead.email}
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
              </Typography>
              <Typography variant="body1" align="left">
                {lead.company ? (
                  <React.Fragment>
                    <b>Company:</b> {lead.company}
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
              </Typography>
              <Typography variant="body1" align="left">
                {lead.title ? (
                  <React.Fragment>
                    <b>Title:</b> {lead.title}
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
              </Typography>
              <Typography variant="body1" align="left">
                {lead.site ? (
                  <React.Fragment>
                    <b>Site:</b> {lead.site}
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
              </Typography>
              <Typography variant="body1" align="left">
                {lead.department ? (
                  <React.Fragment>
                    <b>Department:</b> {lead.department}
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
              </Typography>
            </Grid>
            <Grid item sm={12} md={4}>
              <Typography
                variant="h6"
                gutterBottom
                align="center"
                style={{ padding: '12px' }}
              >
                Notes
              </Typography>
              <InputLabel id="demo-multiple-chip-label">
                Notes are Visible to All Team Members
              </InputLabel>
              <TextField
                // placeholder="Example..."
                // fullWidth
                style={{ width: '90%' }}
                value={this.state.note}
                multiline
                rows={4}
                maxRows={10}
                id="outlined-basic"
                variant="outlined"
                onChange={(event) => this.noteTextUpdate(event)}
                error={
                  this.state.validationError && !this.state.noteText
                    ? true
                    : false
                }
              />
              <br />
              <br />
              {submitButton}
            </Grid>
            <Grid item sm={12} md={4}>
              <Typography
                variant="h6"
                gutterBottom
                align="center"
                style={{ padding: '12px' }}
              >
                Tags
              </Typography>
              <InputLabel id="demo-multiple-chip-label">
                Individual Descriptors For Your Lead
              </InputLabel>

              <TextField
                // placeholder="Example..."
                // fullWidth
                style={{ width: '90%' }}
                value={this.state.newTag}
                multiline
                rows={4}
                maxRows={10}
                id="outlined-basic"
                variant="outlined"
                onChange={(event) => this.tagTextUpdate(event)}
                error={
                  this.state.validationError && !this.state.newTag
                    ? true
                    : false
                }
              />
              <br />
              <br />
              {tagSubmitButton}
              <br />
              <br />
              {renderTags}
              <br />
              <br />
            </Grid>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">Type</TableCell>
                    <TableCell align="center">Time</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lead.actions.map((companyLeads, index) => {
                    return (
                      <React.Fragment key={index}>
                        <TableRow>
                          <TableCell align="center">
                            {companyLeads.name}
                          </TableCell>
                          <TableCell align="center">
                            {companyLeads.type}
                          </TableCell>
                          <TableCell align="center">
                            {`${moment(companyLeads.createdAt).format(
                              'MMMM Do, h:mm a'
                            )}`}
                          </TableCell>
                          <TableCell align="center">
                            {companyLeads.action}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      // getChatMessages: getChatMessages,
      // addChatSubscription: addChatSubscription,
      // setChatMembership: setChatMembership,
      // selectActivePrivateChat: selectActivePrivateChat,
      // setChatbar: setChatbar,
      // viewPrivateChat: viewPrivateChat,
      // setChatWindow,
      // twilioCreateJoinOneOnOneConversation,
      trackAction: trackAction,
      getLeadsArray: getLeadsArray,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    chat: state.chat,
    debug: state.debug,
    event: state.event,
    conversations: state.twilio.conversations,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(Leads));
