import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTriggerNotification } from '../Store/Reducers/twilio';
import sound from '../Assets/Sharp.ogg';
// import sound from '../Assets/ping.mp3';

const NotificationWatcher = () => {
  const triggerNotification = useSelector(getTriggerNotification);

  const useAudio = (url) => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);

    const toggle = () => setPlaying(!playing);

    useEffect(() => {
      playing ? audio.play() : audio.pause();
    }, [playing]);

    useEffect(() => {
      audio.addEventListener('ended', () => setPlaying(false));
      return () => {
        audio.removeEventListener('ended', () => setPlaying(false));
      };
    }, []);

    return [playing, toggle];
  };
  const [playing, toggle] = useAudio(sound);

  useEffect(() => {
    if (triggerNotification > 0) toggle();
  }, [triggerNotification]);

  return null;
};

export default NotificationWatcher;
